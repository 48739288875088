const ActivitiesActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch ACTIVITIES
	FETCH_ALL_ACTIVITIES_START: "FETCH_ALL_ACTIVITIES_START",
	FETCH_ALL_ACTIVITIES_SUCCESS: "FETCH_ALL_ACTIVITIES_SUCCESS",
	FETCH_ALL_ACTIVITIES_FAILURE: "FETCH_ALL_ACTIVITIES_FAILURE",
	// CREATE ACTIVITIES
	CREATE_ACTIVITIES_START: "CREATE_ACTIVITIES_START",
	CREATE_ACTIVITIES_SUCCESS: "CREATE_ACTIVITIES_SUCCESS",
	CREATE_ACTIVITIES_FAILURE: "CREATE_ACTIVITIES_FAILURE",
	// Update ACTIVITIES
	UPDATE_ACTIVITIES_START: "UPDATE_ACTIVITIES_START",
	UPDATE_ACTIVITIES_SUCCESS: "UPDATE_ACTIVITIES_SUCCESS",
	UPDATE_ACTIVITIES_FAILURE: "UPDATE_ACTIVITIES_FAILURE",
	// Delete ACTIVITIES
	DELETE_ACTIVITIES_START: "DELETE_ACTIVITIES_START",
	DELETE_ACTIVITIES_SUCCESS: "DELETE_ACTIVITIES_SUCCESS",
	DELETE_ACTIVITIES_FAILURE: "DELETE_ACTIVITIES_FAILURE",
};

export default ActivitiesActionTypes;
