import MapsActionTypes from "./maps.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	// filters
	truck: "",
	startDate: null,
	trucks: [],
	// maps
	geoJSON: null,
};

const mapsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MapsActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case MapsActionTypes.FETCH_TRUCKS_START:
			return {
				...state,
				loading: true,
			};
		case MapsActionTypes.FETCH_TRUCKS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case MapsActionTypes.SET_GEO_JSON:
			return {
				...state,
				geoJSON: action.payload,
			};
		case MapsActionTypes.FETCH_TRUCKS_SUCCESS:
			return {
				...state,
				trucks: action.payload,
				loading: false,
			};
		case MapsActionTypes.SET_TRUCK:
			return {
				...state,
				truck: action.payload,
			};
		case MapsActionTypes.SET_ACTIVITY:
			return {
				...state,
				activity: action.payload,
			};
		case MapsActionTypes.SET_START_DATE:
			return {
				...state,
				startDate: action.payload,
			};
		case MapsActionTypes.RESET_MAPS_FILTER:
			return {
				...state,
				truck: "",
				startDate: null,
			};
		default:
			return state;
	}
};

export default mapsReducer;
