const ActivitiesActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	//Fetch RAWAT
	FETCH_RAWAT_START: "FETCH_RAWAT_START",
	FETCH_RAWAT_SUCCESS: "FETCH_RAWAT_SUCCESS",
	FETCH_RAWAT_FAILURE: "FETCH_RAWAT_FAILURE",
	
};

export default ActivitiesActionTypes;
