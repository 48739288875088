import axios from "axios";
import moment from "moment";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BACKEND_BASE_URL } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import { selectCurrentUser } from "../user/user.selector";
import {
	clearActivitiesTypeError,
	fetchAllActivitiesTypeSuccess,
	fetchAllActivitiesTypeFailure,
	createActivitiesTypeSuccess,
	createActivitiesTypeFailure,
	updateActivitiesTypeSuccess,
	updateActivitiesTypeFailure,
	deleteActivitiesTypeSuccess,
	deleteActivitiesTypeFailure,
	fetchAllActivitiesTypeStart,
} from "./activities-categories.action";
import ActivitiesTypeActionTypes from "./activities-categories.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";

export function* fetchAllActivitiesType() {
	const company = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltCompany: company.id,
		fltDeleted: 0,
		pageSize: 0,
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BACKEND_BASE_URL}/activity-categories?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const afdeling = response.data.records;

		yield put(fetchAllActivitiesTypeSuccess(afdeling));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearActivitiesTypeError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAllActivitiesTypeFailure(errorMessage));
	}
}

export function* onFetchAllActivitiesTypeStart() {
	yield takeLatest(
		ActivitiesTypeActionTypes.FETCH_ALL_ACTIVITIES_TYPE_START,
		fetchAllActivitiesType
	);
}

export function* createActivitiesType({ payload }) {
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	const info = payload;
	try {
		const uuid = yield axios({
			method: "GET",
			url: "https://www.uuidgenerator.net/api/version1",
		});

		const response = yield axios({
			method: "POST",
			url: `${BACKEND_BASE_URL}/activity-categories`,
			data: {
				companyId: company.id,
				companyName: company.name,
				name: info.name,
				isDeleted: 0,
				createdBy: currentUser.username,
				createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				deletedBy: null,
				deletedTime: null,
				id: uuid.data,
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(createActivitiesTypeSuccess());
		yield put(fetchAllActivitiesTypeStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearActivitiesTypeError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(createActivitiesTypeFailure(errorMessage));
	}
}

export function* onCreateActivitiesTypeStart() {
	yield takeLatest(ActivitiesTypeActionTypes.CREATE_ACTIVITIES_TYPE_START, createActivitiesType);
}

export function* updateActivitiesType({ payload }) {
	const { id, info, version } = payload;
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	try {
		const response = yield axios({
			method: "PUT",
			url: `${BACKEND_BASE_URL}/activity-categories/${id}`,
			data: {
				companyId: company.id,
				companyName: company.name,
				name: info.name,
				updatedBy: currentUser.username,
				updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				id: id,
				version: version, 
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(updateActivitiesTypeSuccess());
		yield put(fetchAllActivitiesTypeStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearActivitiesTypeError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateActivitiesTypeFailure(errorMessage));
	}
}

export function* onUpdateActivitiesTypeStart() {
	yield takeLatest(ActivitiesTypeActionTypes.UPDATE_ACTIVITIES_TYPE_START, updateActivitiesType);
}

export function* deleteActivitiesType({ payload }) {
	const id = payload;
	try {
		const response = yield axios({
			method: "DELETE",
			url: `${BACKEND_BASE_URL}/activity-categories/${id}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(deleteActivitiesTypeSuccess());
		yield put(fetchAllActivitiesTypeStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearActivitiesTypeError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(deleteActivitiesTypeFailure(errorMessage));
	}
}

export function* onDeleteActivitiesTypeStart() {
	yield takeLatest(ActivitiesTypeActionTypes.DELETE_ACTIVITIES_TYPE_START, deleteActivitiesType);
}

export function* activitiesCategoriesSagas() {
	yield all([
		call(onFetchAllActivitiesTypeStart),
		call(onCreateActivitiesTypeStart),
		call(onUpdateActivitiesTypeStart),
		call(onDeleteActivitiesTypeStart),
	]);
}
