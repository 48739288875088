import axios from "axios";
import moment from "moment";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BACKEND_BASE_URL } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import { selectCurrentUser } from "../user/user.selector";
import {
	clearActivitiesError,
	fetchActivitiesSuccess,
	fetchActivitiesFailure,
	createActivitiesSuccess,
	createActivitiesFailure,
	updateActivitiesSuccess,
	updateActivitiesFailure,
	deleteActivitiesSuccess,
	deleteActivitiesFailure,
	fetchActivitiesStart,
} from "./activities-type.action";
import ActivitiesActionTypes from "./activities-type.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";

export function* fetchActivities({payload}) {
	const company = yield select(selectCurrentUserCompany);
	const filters= payload;
	const query = queryString.stringify({
		fltCompany: company.id,
		fltDeleted: 0,
		pageSize: 0,
		...filters
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BACKEND_BASE_URL}/activity-types?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const activities = response.data.records;

		yield put(fetchActivitiesSuccess(activities));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearActivitiesError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchActivitiesFailure(errorMessage));
	}
}

export function* onFetchActivitiesStart() {
	yield takeLatest(
		ActivitiesActionTypes.FETCH_ALL_ACTIVITIES_START,
		fetchActivities
	);
}


export function* createActivities({ payload }) {
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	const activitiesInfo = payload;
	try {
		const uuid = yield axios({
			method: "GET",
			url: "https://www.uuidgenerator.net/api/version1",
		});

		const response = yield axios({
			method: "POST",
			url: `${BACKEND_BASE_URL}/activity-types`,
			data: {
				name: activitiesInfo.name,
				companyId: company.id,
				code: activitiesInfo.code,
				type: activitiesInfo.type,
				isDeleted: 0,
				createdBy: currentUser.username,
				createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				deletedBy: null,
				deletedTime: null,
				id: uuid.data,
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(createActivitiesSuccess());
		yield put(fetchActivitiesStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearActivitiesError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(createActivitiesFailure(errorMessage));
	}
}

export function* onCreateActivitiesStart() {
	yield takeLatest(ActivitiesActionTypes.CREATE_ACTIVITIES_START, createActivities);
}

export function* updateActivities({ payload }) {
	const { id, info, version } = payload;
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	try {
		const response = yield axios({
			method: "PUT",
			url: `${BACKEND_BASE_URL}/activity-types/${id}`,
			data: {
				name: info.name,
				companyId: company.id,
				code: info.code,
				type: info.type,
				updatedBy: currentUser.username,
				updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				id: id,
				version: version, 
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(updateActivitiesSuccess());
		yield put(fetchActivitiesStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearActivitiesError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateActivitiesFailure(errorMessage));
	}
}

export function* onUpdateActivitiesStart() {
	yield takeLatest(ActivitiesActionTypes.UPDATE_ACTIVITIES_START, updateActivities);
}

export function* deleteActivities({ payload }) {
	const id = payload;
	try {
		const response = yield axios({
			method: "DELETE",
			url: `${BACKEND_BASE_URL}/activity-types/${id}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(deleteActivitiesSuccess());
		yield put(fetchActivitiesStart());
		
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearActivitiesError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(deleteActivitiesFailure(errorMessage));
	}
}

export function* onDeleteActivitiesStart() {
	yield takeLatest(ActivitiesActionTypes.DELETE_ACTIVITIES_START, deleteActivities);
}

export function* activitiesTypeSagas() {
	yield all([
		call(onFetchActivitiesStart),
		call(onCreateActivitiesStart),
		call(onUpdateActivitiesStart),
		call(onDeleteActivitiesStart),
	]);
}
