import ActivitiesActionTypes from "./logging-rawat.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	rawat:[]
};

const loggingRawatReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActivitiesActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case ActivitiesActionTypes.FETCH_RAWAT_START:
			return {
				...state,
				loading: true,
			};
		case ActivitiesActionTypes.FETCH_RAWAT_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case ActivitiesActionTypes.FETCH_RAWAT_SUCCESS: {
			return {
				...state,
				rawat: action.payload,
				loading: false,
			};
		}
		default:
			return state;
	}
};

export default loggingRawatReducer;
