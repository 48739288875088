import BlockActionTypes from "./block.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	plural: [],
	singular: null,
};

const blockReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BlockActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case BlockActionTypes.FETCH_BLOCKS_START:
		case BlockActionTypes.FETCH_BLOCKS_BY_COMPANY_ID_START:
		case BlockActionTypes.FETCH_BLOCKS_BY_ID_START:
		case BlockActionTypes.REGISTER_BLOCKS_START:
		case BlockActionTypes.UPDATE_BLOCKS_START:
		case BlockActionTypes.IMPORT_BLOCKS_START:
		case BlockActionTypes.DELETE_BLOCKS_START:
			return {
				...state,
				loading: true,
			};
		case BlockActionTypes.FETCH_BLOCKS_BY_COMPANY_ID_FAILURE:
		case BlockActionTypes.FETCH_BLOCKS_BY_ID_FAILURE:
		case BlockActionTypes.FETCH_BLOCKS_FAILURE:
		case BlockActionTypes.REGISTER_BLOCKS_FAILURE:
		case BlockActionTypes.UPDATE_BLOCKS_FAILURE:
		case BlockActionTypes.IMPORT_BLOCKS_FAILURE:
		case BlockActionTypes.DELETE_BLOCKS_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};

		case BlockActionTypes.FETCH_BLOCKS_BY_COMPANY_ID_SUCCESS:
		case BlockActionTypes.FETCH_BLOCKS_SUCCESS:
		case BlockActionTypes.REGISTER_BLOCKS_SUCCESS: {
			return {
				...state,
				plural: action.payload,
				loading: false,
			};
		}
		case BlockActionTypes.FETCH_BLOCKS_BY_ID_SUCCESS: {
			return {
				...state,
				singular: action.payload,
				loading: false,
			};
		}
		case BlockActionTypes.UPDATE_BLOCKS_SUCCESS:
		case BlockActionTypes.IMPORT_BLOCKS_SUCCESS:
		case BlockActionTypes.DELETE_BLOCKS_SUCCESS:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

export default blockReducer;
