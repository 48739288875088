import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BACKEND_BASE_URL } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import {
  clearTaksasiActivityError,
  fetchTaksasiActivitiesFailure,
  fetchTaksasiActivitiesSuccess,
  fetchTaksasiDetailFailure,
  fetchTaksasiDetailSuccess,
  fetchTaksasiDetailTableFailure,
  fetchTaksasiDetailTableSuccess,
} from "./taksasi-activity.action";
import TaksasiActivityActionTypes from "./taksasi-activity.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";

export function* fetchTaksasiActivities({ payload }) {
  const filters = payload;
  const userCompany = yield select(selectCurrentUserCompany);
  const query = queryString.stringify({
    fltCompany: userCompany.id,
    pageSize: 0,
    ...filters,
  });

  try {
    const response = yield axios({
      method: "GET",
      url: `${BACKEND_BASE_URL}/harvesting-estimates?${query}`,
    });

    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    const harvestingEstimates = response.data.records;
    yield put(fetchTaksasiActivitiesSuccess(harvestingEstimates));
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data.message) || error.message;
    const snackbarData = {
      message: errorMessage,
      color: "error",
      place: "bl",
      dispatchActions: [clearTaksasiActivityError],
    };
    yield put(openSnackbar(snackbarData));
    yield put(fetchTaksasiActivitiesFailure(errorMessage));
  }
}

export function* onFetchTaksasiActivitiesStart() {
  yield takeLatest(
    TaksasiActivityActionTypes.FETCH_TAKSASI_ACTIVITIES_START,
    fetchTaksasiActivities
  );
}

export function* taksasiDetails({ payload }) {
  const idTaksasi = payload;
  try {
    const response = yield axios({
      method: "GET",
      url: `${BACKEND_BASE_URL}/harvesting-estimates/${idTaksasi}`,
    });

    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    const harvestingEstimates = response.data.record;
    yield put(fetchTaksasiDetailSuccess(harvestingEstimates));
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data.message) || error.message;
    const snackbarData = {
      message: errorMessage,
      color: "error",
      place: "bl",
      dispatchActions: [clearTaksasiActivityError],
    };
    yield put(openSnackbar(snackbarData));
    yield put(fetchTaksasiDetailFailure(errorMessage));
  }
}

export function* onFetchTaksasiDetailStart() {
  yield takeLatest(
    TaksasiActivityActionTypes.FETCH_TAKSASI_DETAIL_START,
    taksasiDetails
  );
}

export function* fetchTaksasiDetailTable({ payload }) {
  const id = payload;
  try {
    const response = yield axios({
      method: "GET",
      url: `${BACKEND_BASE_URL}/harvesting-estimates/${id}/details`,
    });

    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    const harvestingEstimates = response.data.records;

    yield put(fetchTaksasiDetailTableSuccess(harvestingEstimates));
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data.message) || error.message;
    const snackbarData = {
      message: errorMessage,
      color: "error",
      place: "bl",
      dispatchActions: [clearTaksasiActivityError],
    };
    yield put(openSnackbar(snackbarData));
    yield put(fetchTaksasiDetailTableFailure(errorMessage));
  }
}

export function* onFetchTaksasiDetailTableStart() {
  yield takeLatest(
    TaksasiActivityActionTypes.FETCH_TAKSASI_DETAIL_TABLE_START,
    fetchTaksasiDetailTable
  );
}

export function* taksasiActivitySagas() {
  yield all([
    call(onFetchTaksasiActivitiesStart),
    call(onFetchTaksasiDetailStart),
    call(onFetchTaksasiDetailTableStart),
  ]);
}
