import {
	KEYCLOAK_ID,
	KEYCLOAK_BASE_URL,
	LOGOUT_CALLBACK_URL,
} from "../../configs/env";
import queryString from "query-string";


const KeycloakLogout = (idToken) =>  {
	const baseUrlKeycloak = KEYCLOAK_BASE_URL;
	return queryString.stringifyUrl({
		url: `${baseUrlKeycloak}/realms/semai-x/protocol/openid-connect/logout`,
		query: {
			client_id: KEYCLOAK_ID,
			id_token_hint: idToken,
			post_logout_redirect_uri: LOGOUT_CALLBACK_URL,
		},
	});
};

export default KeycloakLogout;
