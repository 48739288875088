// Configurations
export const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_ID;
export const FACEBOOK_SECRET = process.env.REACT_APP_FACEBOOK_SECRET;

export const GOOGLE_ID = process.env.REACT_APP_GOOGLE_ID;
export const GOOGLE_SECRET = process.env.REACT_APP_GOOGLE_SECRET;
export const INSTAGRAM_ID = process.env.REACT_APP_INSTAGRAM_ID;
export const LINKEDIN_ID = process.env.REACT_APP_LINKEDIN_ID;
export const GITHUB_ID = process.env.REACT_APP_GITHUB_ID;
export const AZURE_ID = process.env.REACT_APP_AZURE_ID;
export const AZURE_TENANT_ID = process.env.REACT_APP_AZURE_TENANT_ID;

export const KEYCLOAK_ID = process.env.REACT_APP_KEYCLOAK_ID;
export const KEYCLOAK_SECRET = process.env.REACT_APP_KEYCLOAK_SECRET;
export const KEYCLOAK_BASE_URL = process.env.REACT_APP_KEYCLOAK_BASE_URL;

export const LOGIN_CALLBACK_URL = process.env.REACT_APP_LOGIN_CALLBACK_URL;
export const LOGOUT_CALLBACK_URL = process.env.REACT_APP_LOGOUT_CALLBACK_URL
export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
