import VehicleTypeActionTypes from "./vehicle-type.types";

export const fetchAllVehicleTypeStart = () => ({
	type: VehicleTypeActionTypes.FETCH_ALL_VEHICLE_TYPE_START,
});

export const fetchAllVehicleTypeSuccess = (allVehicleType) => ({
	type: VehicleTypeActionTypes.FETCH_ALL_VEHICLE_TYPE_SUCCESS,
	payload: allVehicleType,
});

export const fetchAllVehicleTypeFailure = (error) => ({
	type: VehicleTypeActionTypes.FETCH_ALL_VEHICLE_TYPE_FAILURE,
	payload: error,
});

export const createVehicleTypeStart = (info) => ({
	type: VehicleTypeActionTypes.CREATE_VEHICLE_TYPE_START,
	payload : info
});

export const createVehicleTypeSuccess = () => ({
	type: VehicleTypeActionTypes.CREATE_VEHICLE_TYPE_SUCCESS,
});

export const createVehicleTypeFailure = (error) => ({
	type: VehicleTypeActionTypes.CREATE_VEHICLE_TYPE_FAILURE,
	payload: error,
});

export const updateVehicleTypeStart = ({ id , info, version }) => ({
	type: VehicleTypeActionTypes.UPDATE_VEHICLE_TYPE_START,
	payload: { id , info, version},
});

export const updateVehicleTypeSuccess = () => ({
	type: VehicleTypeActionTypes.UPDATE_VEHICLE_TYPE_SUCCESS,
});

export const updateVehicleTypeFailure = (error) => ({
	type: VehicleTypeActionTypes.UPDATE_VEHICLE_TYPE_FAILURE,
	payload: error,
});

export const deleteVehicleTypeStart = ( id ) => ({
	type: VehicleTypeActionTypes.DELETE_VEHICLE_TYPE_START,
	payload: id,
});

export const deleteVehicleTypeSuccess = () => ({
	type: VehicleTypeActionTypes.DELETE_VEHICLE_TYPE_SUCCESS,
});

export const deleteVehicleTypeFailure = (error) => ({
	type: VehicleTypeActionTypes.DELETE_AFDELING_FAILURE,
	payload: error,
});

export const clearVehicleTypeError = () => ({
	type: VehicleTypeActionTypes.CLEAR_ERROR,
});
