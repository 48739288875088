const BlockActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch Block by company id
	FETCH_BLOCKS_BY_COMPANY_ID_START: "FETCH_BLOCKS_BY_COMPANY_ID_START",
	FETCH_BLOCKS_BY_COMPANY_ID_SUCCESS: "FETCH_BLOCKS_BY_COMPANY_ID_SUCCESS",
	FETCH_BLOCKS_BY_COMPANY_ID_FAILURE: "FETCH_BLOCKS_BY_COMPANY_ID_FAILURE",
	// Fetch BLOCK
	FETCH_BLOCKS_START: "FETCH_BLOCKS_START",
	FETCH_BLOCKS_SUCCESS: "FETCH_BLOCKS_SUCCESS",
	FETCH_BLOCKS_FAILURE: "FETCH_BLOCKS_FAILURE",
	// Fetch Block by company id
	FETCH_BLOCKS_BY_ID_START: "FETCH_BLOCKS_BY_ID_START",
	FETCH_BLOCKS_BY_ID_SUCCESS: "FETCH_BLOCKS_BY_ID_SUCCESS",
	FETCH_BLOCKS_BY_ID_FAILURE: "FETCH_BLOCKS_BY_ID_FAILURE",
	// REGISTER BLOCK
	REGISTER_BLOCKS_START: "REGISTER_BLOCKS_START",
	REGISTER_BLOCKS_SUCCESS: "REGISTER_BLOCKS_SUCCESS",
	REGISTER_BLOCKS_FAILURE: "REGISTER_BLOCKS_FAILURE",
	// IMPORT BLOCK
	IMPORT_BLOCKS_START: "IMPORT_BLOCKS_START",
	IMPORT_BLOCKS_SUCCESS: "IMPORT_BLOCKS_SUCCESS",
	IMPORT_BLOCKS_FAILURE: "IMPORT_BLOCKS_FAILURE",
	// Update BLOCKS
	UPDATE_BLOCKS_START: "UPDATE_BLOCKS_START",
	UPDATE_BLOCKS_SUCCESS: "UPDATE_BLOCKS_SUCCESS",
	UPDATE_BLOCKS_FAILURE: "UPDATE_BLOCKS_FAILURE",
	// DELETE BLOCKS
	DELETE_BLOCKS_START: "DELETE_BLOCKS_START",
	DELETE_BLOCKS_SUCCESS: "DELETE_BLOCKS_SUCCESS",
	DELETE_BLOCKS_FAILURE: "DELETE_BLOCKS_FAILURE",
};

export default BlockActionTypes;
