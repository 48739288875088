import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BACKEND_BASE_URL } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import {
	clearRawatError,
	fetchRawatSuccess,
	fetchRawatFailure,
	fetchRawatStart,
} from "./logging-rawat.action";
import ActivitiesActionTypes from "./logging-rawat.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";

export function* fetchRawat({payload}) {
	const filter = payload;
	const company = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltDeleted: 0,
		pageSize: 0,
		...filter
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BACKEND_BASE_URL}/upkeeps?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const activities = response.data.records;

		yield put(fetchRawatSuccess(activities));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearRawatError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchRawatFailure(errorMessage));
	}
}

export function* onFetchRawatStart() {
	yield takeLatest(
		ActivitiesActionTypes.FETCH_RAWAT_START,
		fetchRawat
	);
}

export function* loggingRawatSagas() {
	yield all([
		call(onFetchRawatStart),
	]);
}
