import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BACKEND_BASE_URL } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import {
	clearBlockError,
	fetchBlocksByCompanyIdFailure,
	fetchBlocksByCompanyIdSuccess,
	fetchBlocksByIdFailure,
	fetchBlocksByIdSuccess,
	fetchBlocksStart,
	fetchBlocksFailure,
	fetchBlocksSuccess,
	registerBlockFailure,
	registerBlockSuccess,
	importBlocksSuccess,
	importBlocksFailure,
	updateBlocksSuccess,
	updateBlocksFailure,
	deleteBlocksSuccess,
	deleteBlocksFailure,
} from "./block.action";
import BlockActionTypes from "./block.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";
import { selectCurrentUser, selectUserToken } from "../user/user.selector";
import moment from "moment";

export function* fetchBlocks({ payload }) {
	const filters = payload;
	if (!!!filters) {
		yield put(fetchBlocksSuccess([]));
		return;
	}
	const company = yield select(selectCurrentUserCompany);
	const data = queryString.stringify({
		fltCompany: company.id,
		pageSize: 0,
		...filters,
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BACKEND_BASE_URL}/blocks?${data}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const blocks = response.data.records;
		yield put(fetchBlocksSuccess(blocks));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearBlockError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchBlocksFailure(errorMessage));
	}
}

export function* registerBlock({ payload }) {
	const info = payload;

	const company = yield select(selectCurrentUserCompany);
	const user = yield select(selectCurrentUser);
	const uuid = yield axios({
		method: "GET",
		url: "https://www.uuidgenerator.net/api/version1",
	});
	const data = {
		estateId: info.estate,
		afdelingId: info.afdeling,
		blockName: info.block,
		companyId: company.id,
		plantedDate: moment(info.tanggalTanam).format("YYYY-MM-DD"),
		averageFruitWeight: info.bjr,
		areaHa: info.area,
		treePerHa: info.sph,
		treeCount: info.pokok,
		rowCount: info.baris,
		tphCount: info.tph,
		sampleRow: info.sampleBaris,
		seedType: info.bibit,
		soilType: info.tanah,
		topographyType:info.topografi,
		growthRate:info.growth,
		estimatedTreeHeight: info.tinggi,
		harvestNorm: info.norm,
		deletedBy: "",
		deletedTime: null,
		isDeleted: 0,
		createdBy: user.username,
		userId: user.id,
		username: user.username,
		createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
		syncedStatus: 0,
		id: uuid.data,
		syncedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
		updatedBy: "",
		updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ")
	};
	try {
		const response = yield axios({
			method: "POST",
			url: `${BACKEND_BASE_URL}/blocks`,
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		});
		if (!response.data.success) {
			throw new Error(response.data.message);
		}
		yield put(registerBlockSuccess());

		yield put(fetchBlocksStart({
				fltDeleted: 0,
			}));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearBlockError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(registerBlockFailure(errorMessage));
	}
}

export function* onRegisterBlockStart() {
	yield takeLatest(BlockActionTypes.REGISTER_BLOCKS_START, registerBlock);
}

export function* fetchBlocksByCompanyId({ payload }) {
	const company = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltCompany: company.id,
		pageSize: 0,
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BACKEND_BASE_URL}/blocks?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const members = response.data.records;
		yield put(fetchBlocksByCompanyIdSuccess(members));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearBlockError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchBlocksByCompanyIdFailure(errorMessage));
	}
}

export function* onFetchBlocksByCompanyIdStart() {
	yield takeLatest(
		BlockActionTypes.FETCH_BLOCKS_BY_COMPANY_ID_START,
		fetchBlocksByCompanyId
	);
}

export function* onFetchBlocksStart() {
	yield takeLatest(BlockActionTypes.FETCH_BLOCKS_START, fetchBlocks);
}

export function* fetchBlocksById({ payload }) {
	const id = payload;
	if (!!!id) {
		yield put(fetchBlocksByIdSuccess());
		return;
	}
	try {
		const response = yield axios({
			method: "GET",
			url: `${BACKEND_BASE_URL}/blocks/${id}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const block = response.data.record;
		yield put(fetchBlocksByIdSuccess(block));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearBlockError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchBlocksByIdFailure(errorMessage));
	}
}

export function* onFetchBlocksByIdStart() {
	yield takeLatest(BlockActionTypes.FETCH_BLOCKS_BY_ID_START, fetchBlocksById);
}

export function* importBlocks({ payload }) {
	const importInfo = payload;

	const formData = new FormData();
	formData.append("content", importInfo.file[0]);

	try {
		const response = yield axios({
			method: "POST",
			url: `${BACKEND_BASE_URL}/blocks/import`,
			headers: {
				"Content-Type": "multipart/form-data",
			},
			data: formData,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(importBlocksSuccess());
		const successInfo = {
			message: "Import Blocks Sukses!",
			color: "success",
			place: "bl",
			dispatchActions: [],
		};
		yield put(openSnackbar(successInfo));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearBlockError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(importBlocksFailure(errorMessage));
	}
}

export function* onImportBlocksStart() {
	yield takeLatest(BlockActionTypes.IMPORT_BLOCKS_START, importBlocks);
}

export function* updateBlock({ payload }) {
	const { id, info, version } = payload;
	const company = yield select(selectCurrentUserCompany);
	const user = yield select(selectCurrentUser);
	const data = {
		id: id,
		estateId: info.estate,
		afdelingId: info.afdeling,
		blockName: info.block,
		plantedDate: moment(info.tanggalTanam).format("YYYY-MM-DD"),
		averageFruitWeight: info.bjr,
		companyId: company.id,
		areaHa: info.area,
		seedType: info.bibit,
		sampleRow: info.sampleBaris,
		soilType: info.tanah,
		treePerHa: info.sph,
		treeCount: info.pokok,
		rowCount: info.baris,
		tphCount: info.tph,
		topographyType:info.topografi,
		growthRate:info.growth,
		estimatedTreeHeight: info.tinggi,
		harvestNorm: info.norm,
		updatedBy: user.username,
		updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
		userId: user.id,
		username: user.username,
		version: version,
	};
	try {
		const response = yield axios({
			method: "PUT",
			url: `${BACKEND_BASE_URL}/blocks/${id}`,
			data: data,
		});
		if (!response.data.success) {
			throw new Error(response.data.message);
		}
		yield put(updateBlocksSuccess());
		yield put(fetchBlocksStart({
			fltDeleted: 0,
		}));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearBlockError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateBlocksFailure(errorMessage));
	}
}

export function* onUpdateBlockStart() {
	yield takeLatest(BlockActionTypes.UPDATE_BLOCKS_START, updateBlock);
}

export function* deleteBlock({ payload }) {
	const id = payload;

	try {
		const response = yield axios({
			method: "DELETE",
			url: `${BACKEND_BASE_URL}/blocks/${id}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(deleteBlocksSuccess());
		yield put(fetchBlocksStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearBlockError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(deleteBlocksFailure(errorMessage));
	}
}

export function* onDeleteBlockStart() {
	yield takeLatest(BlockActionTypes.DELETE_BLOCKS_START, deleteBlock);
}

export function* blockSagas() {
	yield all([
		call(onFetchBlocksByCompanyIdStart),
		call(onFetchBlocksByIdStart),
		call(onFetchBlocksStart),
		call(onRegisterBlockStart),
		call(onImportBlocksStart),
		call(onUpdateBlockStart),
		call(onDeleteBlockStart),
	]);
}
