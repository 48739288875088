import ActivityPlanActionTypes from "./activity-planning.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	singular: null,
	plural: [],
};

const activitiesPlanReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActivityPlanActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case ActivityPlanActionTypes.FETCH_ACTIVITIES_PLAN_START:
		case ActivityPlanActionTypes.UPDATE_ACTIVITIES_PLAN_START:
		case ActivityPlanActionTypes.CREATE_ACTIVITIES_PLAN_START:
			return {
				...state,
				loading: true,
			};
		case ActivityPlanActionTypes.UPDATE_ACTIVITIES_PLAN_FAILURE:
		case ActivityPlanActionTypes.FETCH_ACTIVITIES_PLAN_FAILURE:
		case ActivityPlanActionTypes.CREATE_ACTIVITIES_PLAN_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case ActivityPlanActionTypes.UPDATE_ACTIVITIES_PLAN_SUCCESS:
		case ActivityPlanActionTypes.CREATE_ACTIVITIES_PLAN_SUCCESS:{
			return {
				...state,
				loading: false,
			};
		}
		case ActivityPlanActionTypes.FETCH_ACTIVITIES_PLAN_SUCCESS: {
			return {
				...state,
				plural: action.payload,
				loading: false,
			};
		}
		default:
			return state;
	}
};

export default activitiesPlanReducer;
