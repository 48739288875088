const RoutePlanActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch ROUTE
	FETCH_ROUTE_PLAN_START: "FETCH_ROUTE_PLAN_START",
	FETCH_ROUTE_PLAN_SUCCESS: "FETCH_ROUTE_PLAN_SUCCESS",
	FETCH_ROUTE_PLAN_FAILURE: "FETCH_ROUTE_PLAN_FAILURE",
	// CREATE ROUTE
	CREATE_ROUTE_PLAN_START: "CREATE_ROUTE_PLAN_START",
	CREATE_ROUTE_PLAN_SUCCESS: "CREATE_ROUTE_PLAN_SUCCESS",
	CREATE_ROUTE_PLAN_FAILURE: "CREATE_ROUTE_PLAN_FAILURE",
};

export default RoutePlanActionTypes;
