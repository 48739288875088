import { Grid, Menu, MenuItem, Tooltip, Zoom } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser, selectUserRole } from "../../redux/user/user.selector";
import { useStyles } from "./Header.styles";
// import { ReactComponent as NotificationIcon } from "../../assets/img/notification-icon.svg";
// import { ReactComponent as ActiveNotificationIcon } from "../../assets/img/notification-icon-active.svg";
import AvatarDefault from "@material-ui/icons/AccountCircle";
import SemaiLogoText from "../../assets/img/semai-logo.png";
import SemaiLogo from "../../assets/img/semaiX_h_white_bg_trans.png";
// import BurgerIcon from "../BurgerIcon/BurgerIcon";
import { logoutStart } from "../../redux/user/user.action";
import HomeIcon from "@material-ui/icons/Home";
import AssignmentIcon from "@material-ui/icons/Assignment";
import StorageIcon from "@material-ui/icons/Storage";
import TimelineIcon from '@material-ui/icons/Timeline';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MapIcon from '@material-ui/icons/Map';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EventNoteIcon from '@material-ui/icons/EventNote';

const Header = (props) => {
	const { currentUser, userLogoutStart, role } = props;
	const classes = useStyles();

	const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);

	const handleClickProfileMenu = (event) => {
		setProfileMenuAnchorEl(event.currentTarget);
	};

	const handleCloseProfileMenu = () => {
		setProfileMenuAnchorEl(null);
	};

	const handleLogout = () => {
		setProfileMenuAnchorEl(null);
		userLogoutStart();
	};

	return (
		<header>
			<Grid
				container
				className={classes.headerContainer}
				justify="space-between"
				alignContent="center"
			>
				<Grid item xs={3}>
					<a href="/">
						<img
							src={SemaiLogo}
							alt="semai"
							className={classes.semaiLogo}
						/>
					</a>
				</Grid>
				<Grid
					item
					xs={7}
					sm={6}
					md={6}
					lg={6}
					container
					justify="flex-end"
					alignItems="center"
				>
					{!!currentUser && (
						<>
							<Grid item>
								<a href="/">
									<Tooltip
										TransitionComponent={Zoom}
										arrow
										title="Homepage"
										aria-label="homepage"
									>
										<HomeIcon className={classes.icon} />
									</Tooltip>
								</a>
							</Grid>
							{ !!role && role.some(element => element == "tenant_administrator"  || element == "supervisor" || element == "clerical") &&	
							(
							<Grid item>
								<a href="/activities">
									<Tooltip
										TransitionComponent={Zoom}
										arrow
										title="Activities"
										aria-label="activities"
									>
										<AssignmentIcon
											className={classes.icon}
										/>
									</Tooltip>
								</a>
							</Grid>)
							}
							{/*<Grid item>
								<a href="/planning">
									<Tooltip
										TransitionComponent={Zoom}
										arrow
										title="Planning"
										aria-label="planning"
									>
										<ScheduleIcon
											className={classes.icon}
										/>
									</Tooltip>
								</a>
							</Grid>
							<Grid item>
								<a href="/activity-planning">
									<Tooltip
										TransitionComponent={Zoom}
										arrow
										title="Activity Planning"
										aria-label="activityPlanning"
									>
										<ReceiptIcon
											className={classes.icon}
										/>
									</Tooltip>
								</a>
							</Grid>
							<Grid item>
								<a href="/route-planning">
									<Tooltip
										TransitionComponent={Zoom}
										arrow
										title="Route Planning"
										aria-label="routePlanning"
									>
										<LinearScaleIcon
											className={classes.icon}
										/>
									</Tooltip>
								</a>
							</Grid>
							<Grid item>
								<a href="/analysis">
									<Tooltip
										TransitionComponent={Zoom}
										arrow
										title="Analysis"
										aria-label="analysis"
									>
										<TimelineIcon
											className={classes.icon}
										/>
									</Tooltip>
								</a>
							</Grid>	*/}
							{ !!role && role.some(element => element == "tenant_administrator"  || element == "supervisor" || element == "clerical") &&	
							(<Grid item>
								<a href="/master-data">
									<Tooltip
										TransitionComponent={Zoom}
										arrow
										title="Master Data"
										aria-label="masterData"
									>
										<StorageIcon className={classes.icon} />
									</Tooltip>
								</a>
							</Grid>)
							}
							{/*<Grid item>
								<a href="/maps/0/0">
									<Tooltip
										TransitionComponent={Zoom}
										arrow
										title="Maps"
										aria-label="maps"
									>
										<MapIcon className={classes.icon} />
									</Tooltip>
								</a>
							</Grid>
							<Grid item>
								<a href="/calendar">
									<Tooltip
										TransitionComponent={Zoom}
										arrow
										title="Maps"
										aria-label="maps"
									>
										<EventNoteIcon className={classes.icon} />
									</Tooltip>
								</a>
							</Grid>*/}
							<Grid item>
								<AvatarDefault
									className={classes.icon}
									aria-controls="profile-menu"
									aria-haspopup="true"
									onClick={handleClickProfileMenu}
								/>
								<Menu
									className={classes.menu}
									id="profile-menu"
									anchorEl={profileMenuAnchorEl}
									keepMounted
									open={Boolean(profileMenuAnchorEl)}
									onClose={handleCloseProfileMenu}
								>
									<a href="/profile">
										<MenuItem
											onClick={handleCloseProfileMenu}
										>
											Profile
										</MenuItem>
									</a>
									<MenuItem onClick={handleLogout}>
										Logout
									</MenuItem>
								</Menu>
							</Grid>
						</>
					)}

					{/* <BurgerIcon open={open} handleOpen={setOpen} /> */}
				</Grid>
			</Grid>
		</header>
	);
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
	role: selectUserRole,
});

const mapDispatchToProps = (dispatch) => ({
	userLogoutStart: () => dispatch(logoutStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
