import moment from "moment";
import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BACKEND_BASE_URL } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import queryString from "query-string";
import {
	clearMonthlyActivityPlanError,
	fetchAllMonthlyActivityPlanStart,
	fetchAllMonthlyActivityPlanSuccess,
	fetchAllMonthlyActivityPlanFailure,
	createMonthlyActivityPlanSuccess,
	createMonthlyActivityPlanFailure,
	updateMonthlyActivityPlanSuccess,
	updateMonthlyActivityPlanFailure,
	deleteMonthlyActivityPlanSuccess,
	deleteMonthlyActivityPlanFailure,
} from "./monthly-activity-plan.action";
import MonthlyActivityPlan from "./monthly-activity-plan.types";
import { selectCurrentUser } from "../user/user.selector";
import { selectCurrentUserCompany } from "../company/company.selector";

export function* fetchAllMonthlyActivityPlan({ payload }) {
	const filters = payload;
	const query = queryString.stringify({
		isDeleted: 0,
		pageSize: 0,
		sidx: "block.blockName",
		sord: "asc",
		...filters,
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BACKEND_BASE_URL}/monthly-activity-plans?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const vehicles = response.data.records;

		yield put(fetchAllMonthlyActivityPlanSuccess(vehicles));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMonthlyActivityPlanError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAllMonthlyActivityPlanFailure(errorMessage));
	}
}

export function* onFetchAllMonthlyActivityPlan() {
	yield takeLatest(MonthlyActivityPlan.FETCH_ALL_MONTHLY_ACTIVITY_PLAN_START, fetchAllMonthlyActivityPlan);
}

export function* createMonthlyActivityPlan({ payload }) {
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	const { info, filter } = payload;
	try {
		const uuid = yield axios({
			method: "GET",
			url: "https://www.uuidgenerator.net/api/version1",
		});

		const response = yield axios({
			method: "POST",
			url: `${BACKEND_BASE_URL}/monthly-activity-plans`,
			data: {
				activityTypeId: info.activityTypeId,
				afdelingId: info.afdelingId,
				blockId: info.blockId,
				companyId: company.id,
				companyName: company.name,
				createdBy: currentUser.name,
				createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				estateId: info.estateId,
				ha: info.ha,
				hk: info.hk,
				hkPerHa: info.hkPerHa,
				id: uuid.data,
				isDeleted: 0,
				planMonth: info.planMonth,
				planYear: info.planYear,
				updatedBy: currentUser.name,
				updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				userId: currentUser.id,
				userName: currentUser.name,
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(createMonthlyActivityPlanSuccess());
		yield put(fetchAllMonthlyActivityPlanStart(filter));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMonthlyActivityPlanError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(createMonthlyActivityPlanFailure(errorMessage));
	}
}

export function* onCreateMonthlyActivityPlan() {
	yield takeLatest(MonthlyActivityPlan.CREATE_MONTHLY_ACTIVITY_PLAN_START, createMonthlyActivityPlan);
}

export function* updateMonthlyActivityPlan({ payload }) {
	const { info, filter } = payload;
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	try {
		const response = yield axios({
			method: "PUT",
			url: `${BACKEND_BASE_URL}/monthly-activity-plans/${info.id}`,
			data: {
				activityTypeId: info.activityTypeId,
				afdelingId: info.afdelingId,
				blockId: info.blockId,
				companyId: company.id,
				companyName: company.name,
				estateId: info.estateId,
				ha: info.ha,
				hk: info.hk,
				hkPerHa: info.hkPerHa,
				id: info.id,
				planMonth: info.planMonth,
				planYear: info.planYear,
				updatedBy: currentUser.name,
				updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				userId: currentUser.id,
				userName: currentUser.name,
				version: info.version + 1,
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(updateMonthlyActivityPlanSuccess());
		yield put(fetchAllMonthlyActivityPlanStart(filter));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMonthlyActivityPlanError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateMonthlyActivityPlanFailure(errorMessage));
	}
}

export function* onUpdateMonthlyActivityPlan() {
	yield takeLatest(MonthlyActivityPlan.UPDATE_MONTHLY_ACTIVITY_PLAN_START, updateMonthlyActivityPlan);
}

export function* deleteMonthlyActivityPlan({ payload }) {
	const { id, filter } = payload;
	try {
		const response = yield axios({
			method: "DELETE",
			url: `${BACKEND_BASE_URL}/monthly-activity-plans/${id}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(deleteMonthlyActivityPlanSuccess());
		yield put(fetchAllMonthlyActivityPlanStart(filter));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMonthlyActivityPlanError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(deleteMonthlyActivityPlanFailure(errorMessage));
	}
}

export function* onDeleteMonthlyActivityPlan() {
	yield takeLatest(MonthlyActivityPlan.DELETE_MONTHLY_ACTIVITY_PLAN_START, deleteMonthlyActivityPlan);
}

export function* monthlyActivityPlanSagas() {
	yield all([
		call(onFetchAllMonthlyActivityPlan),
		call(onCreateMonthlyActivityPlan),
		call(onUpdateMonthlyActivityPlan),
		call(onDeleteMonthlyActivityPlan),
	]);
}
