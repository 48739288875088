import axios from "axios";
import { BACKEND_BASE_URL } from "../../configs/env";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import queryString from "query-string";
import moment from "moment";

import { openSnackbar } from "../snackbar/snackbar.action";
import HarvesterActionTypes from "./harvester.types";
import { selectCurrentUserCompany } from "../company/company.selector";
import { selectCurrentUser } from "../user/user.selector";
import {
    clearHarvesterError,
    fetchHarvestersStart,
	fetchHarvestersSuccess,
	fetchHarvestersFailure,
    insertHarvesterSuccess,
    insertHarvesterFailure,
    updateHarvesterSuccess,
    updateHarvesterFailure,
    deleteHarvesterSuccess,
    deleteHarvesterFailure,
    importHarvestersSuccess,
    importHarvestersFailure,
} from "./harvester.action";

export function* fetchHarvesters() {
    const company = yield select(selectCurrentUserCompany);
    const query = queryString.stringify({
		pageSize: 0,
        fltCompany: company.id,
	});

    try {
        const response = yield axios({
			method: "GET",
			url: `${BACKEND_BASE_URL}/harvesters?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const harvesters = response.data.records;
        yield put(fetchHarvestersSuccess(harvesters));
    } catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearHarvesterError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchHarvestersFailure(errorMessage));
    }
}

export function* onFetchHarvestersStart() {
	yield takeLatest(HarvesterActionTypes.FETCH_HARVESTERS_START, fetchHarvesters);
}

export function* insertHarvester({ payload }) {
    const harvesterInfo = payload;
    const company = yield select(selectCurrentUserCompany);
    const currentUser = yield select(selectCurrentUser);
	
    try {
        const uuid = yield axios({
			method: "GET",
			url: "https://www.uuidgenerator.net/api/version1",
		});
        
        const response = yield axios({
			method: "POST",
			url: `${BACKEND_BASE_URL}/harvesters`,
            data: {
                appMode: 1,
                companyId: company.id,
                createdBy: currentUser.username,
                createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
                defaultAfdelingId: harvesterInfo.defaultAfdelingId,
                defaultEstateId: harvesterInfo.defaultEstateId,
                // deletedBy:
                // deletedTime:
                employeeNo: harvesterInfo.employeeNo,
                id: uuid.data,
                isDeleted: 0,
                name: harvesterInfo.name,
                phone: harvesterInfo.phone,
                // photoFileId:
                status: harvesterInfo.status ? 1 : 0,
                // syncedStatus:
                // syncedTime:
                updatedBy: currentUser.username,
                updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
                userId: currentUser.id,  // TODO: change user ID with other than current user ID
                version: 1,
            },
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

        yield put(insertHarvesterSuccess());
        yield put(fetchHarvestersStart());
    } catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearHarvesterError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(insertHarvesterFailure(errorMessage));
    }
}

export function* onInsertHarvesterStart() {
	yield takeLatest(HarvesterActionTypes.INSERT_HARVESTER_START, insertHarvester);
}

export function* updateHarvester({ payload }) {
    const harvesterInfo = payload;
    const company = yield select(selectCurrentUserCompany);
    const currentUser = yield select(selectCurrentUser);

    try {        
        const response = yield axios({
			method: "PUT",
			url: `${BACKEND_BASE_URL}/harvesters/${harvesterInfo.id}`,
            data: {
                appMode: 1,
                companyId: company.id,
                // createdBy: currentUser.username,
                // createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
                defaultAfdelingId: harvesterInfo.defaultAfdelingId,
                defaultEstateId: harvesterInfo.defaultEstateId,
                // deletedBy:
                // deletedTime:
                employeeNo: harvesterInfo.employeeNo,
                id: harvesterInfo.id,
                isDeleted: 0,
                name: harvesterInfo.name,
                phone: harvesterInfo.phone,
                // photoFileId:
                status: harvesterInfo.status ? 1 : 0,
                // syncedStatus:
                // syncedTime:
                updatedBy: currentUser.username,
                updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
                userId: currentUser.id,  // TODO: change user ID with other than current user ID
                version: harvesterInfo.version + 1,
            },
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

        yield put(updateHarvesterSuccess());
        yield put(fetchHarvestersStart());
    } catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearHarvesterError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateHarvesterFailure(errorMessage));
    }
}

export function* onUpdateHarvesterStart() {
	yield takeLatest(HarvesterActionTypes.UPDATE_HARVESTER_START, updateHarvester);
}

export function* deleteHarvester({ payload }) {
    const harvesterId = payload;

    try {
        const response = yield axios({
			method: "DELETE",
			url: `${BACKEND_BASE_URL}/harvesters/${harvesterId}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

        yield put(deleteHarvesterSuccess());
        yield put(fetchHarvestersStart());
    } catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearHarvesterError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(deleteHarvesterFailure(errorMessage));
    }
}

export function* onDeleteHarvesterStart() {
	yield takeLatest(HarvesterActionTypes.DELETE_HARVESTER_START, deleteHarvester);
}

export function* importHarvesters({ payload }) {
    const importInfo = payload;

    const formData = new FormData();
	formData.append("content", importInfo.file[0]);

    try {
		const response = yield axios({
			method: "POST",
			url: `${BACKEND_BASE_URL}/harvesters/import`,
			headers: {
				"Content-Type": "multipart/form-data",
			},
			data: formData,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(importHarvestersSuccess());
		yield put(fetchHarvestersStart());
		const successInfo = {
			message: "Import Harvester Sukses!",
			color: "success",
			place: "bl",
			dispatchActions: [],
		};
		yield put(openSnackbar(successInfo));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearHarvesterError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(importHarvestersFailure(errorMessage));
	}
}

export function* onImportHarvestersStart() {
	yield takeLatest(HarvesterActionTypes.IMPORT_HARVESTERS_START, importHarvesters);
}

export function* harvesterSagas() {
	yield all([
        call(onFetchHarvestersStart),
        call(onInsertHarvesterStart),
        call(onUpdateHarvesterStart),
        call(onDeleteHarvesterStart),
        call(onImportHarvestersStart),
    ]);
}