import ActivityPlanActionTypes from "./activity-planning.types";

export const fetchActivitiesPlanStart = (username) => ({
	type: ActivityPlanActionTypes.FETCH_ACTIVITIES_PLAN_START,
	payload: username,
});

export const fetchActivitiesPlanSuccess = (company) => ({
	type: ActivityPlanActionTypes.FETCH_ACTIVITIES_PLAN_SUCCESS,
	payload: company,
});

export const fetchActivitiesPlanFailure = (error) => ({
	type: ActivityPlanActionTypes.FETCH_ACTIVITIES_PLAN_FAILURE,
	payload: error,
});

export const createActivitiesPlanStart = (info) => ({
	type: ActivityPlanActionTypes.CREATE_ACTIVITIES_PLAN_START,
	payload : info
});

export const createActivitiesPlanSuccess = () => ({
	type: ActivityPlanActionTypes.CREATE_ACTIVITIES_PLAN_SUCCESS,
});

export const createActivitiesPlanFailure = (error) => ({
	type: ActivityPlanActionTypes.CREATE_ACTIVITIES_PLAN_FAILURE,
	payload: error,
});


export const updateActivitiesPlanStart = (info) => ({
	type: ActivityPlanActionTypes.UPDATE_ACTIVITIES_PLAN_START,
	payload : info
});

export const updateActivitiesPlanSuccess = () => ({
	type: ActivityPlanActionTypes.UPDATE_ACTIVITIES_PLAN_SUCCESS,
});

export const updateActivitiesPlanFailure = (error) => ({
	type: ActivityPlanActionTypes.UPDATE_ACTIVITIES_PLAN_FAILURE,
	payload: error,
});

export const clearActivitiesError = () => ({
	type: ActivityPlanActionTypes.CLEAR_ERROR,
});
