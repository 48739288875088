const ActivitiesActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch ANGKUT
	FETCH_ANGKUT_START: "FETCH_ANGKUT_START",
	FETCH_ANGKUT_SUCCESS: "FETCH_ANGKUT_SUCCESS",
	FETCH_ANGKUT_FAILURE: "FETCH_ANGKUT_FAILURE",
	
};

export default ActivitiesActionTypes;
