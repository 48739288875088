import { KEYCLOAK_ID, KEYCLOAK_BASE_URL, SERVER_ENV } from "../../configs/env";
import queryString from "query-string";
import URL from "url";

const KeycloakRefreshToken = async (refreshToken) => {
	const baseUrlKeycloak = KEYCLOAK_BASE_URL;
	let params = {
		client_id: KEYCLOAK_ID,
		grant_type: "refresh_token",
		refresh_token: refreshToken,
	};

	const post_data = queryString.stringify(params);
	let parsedUrl = URL.parse(
		`${baseUrlKeycloak}/realms/semai-x/protocol/openid-connect/token`,
		true
	);

	let realHeaders = {};
	realHeaders["Host"] = parsedUrl.host;
	realHeaders["Content-Length"] = post_data.length;
	realHeaders["Content-Type"] = "application/x-www-form-urlencoded";

	const options = {
		host: parsedUrl.hostname,
		port: parsedUrl.port,
		path: parsedUrl.pathname,
		method: "POST",
		headers: realHeaders,
	};

	const payload = Object.assign(
		{
			body: post_data,
		},
		options
	);

	let response = await fetch(
		`${baseUrlKeycloak}/realms/semai-x/protocol/openid-connect/token`,
		payload
	);

	let res = await response.json();
	return res;
};

export default KeycloakRefreshToken;
