import ActivitiesActionTypes from "./activities-type.types";

export const fetchActivitiesStart = (username) => ({
	type: ActivitiesActionTypes.FETCH_ALL_ACTIVITIES_START,
	payload: username,
});

export const fetchActivitiesSuccess = (company) => ({
	type: ActivitiesActionTypes.FETCH_ALL_ACTIVITIES_SUCCESS,
	payload: company,
});

export const fetchActivitiesFailure = (error) => ({
	type: ActivitiesActionTypes.FETCH_ALL_ACTIVITIES_FAILURE,
	payload: error,
});

export const createActivitiesStart = (info) => ({
	type: ActivitiesActionTypes.CREATE_ACTIVITIES_START,
	payload : info
});

export const createActivitiesSuccess = () => ({
	type: ActivitiesActionTypes.CREATE_ACTIVITIES_SUCCESS,
});

export const createActivitiesFailure = (error) => ({
	type: ActivitiesActionTypes.CREATE_ACTIVITIES_FAILURE,
	payload: error,
});

export const updateActivitiesStart = ({ id , info, version }) => ({
	type: ActivitiesActionTypes.UPDATE_ACTIVITIES_START,
	payload: { id , info, version},
});

export const updateActivitiesSuccess = () => ({
	type: ActivitiesActionTypes.UPDATE_ACTIVITIES_SUCCESS,
});

export const updateActivitiesFailure = (error) => ({
	type: ActivitiesActionTypes.UPDATE_ACTIVITIES_FAILURE,
	payload: error,
});

export const deleteActivitiesStart = ( id ) => ({
	type: ActivitiesActionTypes.DELETE_ACTIVITIES_START,
	payload: id,
});

export const deleteActivitiesSuccess = () => ({
	type: ActivitiesActionTypes.DELETE_ACTIVITIES_SUCCESS,
});

export const deleteActivitiesFailure = (error) => ({
	type: ActivitiesActionTypes.DELETE_ACTIVITIES_FAILURE,
	payload: error,
});

export const clearActivitiesError = () => ({
	type: ActivitiesActionTypes.CLEAR_ERROR,
});
