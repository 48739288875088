import VehicleActionTypes from "./vehicle.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	allVehicle: [],
};

const vehicleReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case VehicleActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case VehicleActionTypes.FETCH_ALL_VEHICLE_START:
		case VehicleActionTypes.CREATE_VEHICLE_START:
		case VehicleActionTypes.UPDATE_VEHICLE_START:
		case VehicleActionTypes.DELETE_VEHICLE_START:
			return {
				...state,
				loading: true,
			};
		case VehicleActionTypes.FETCH_ALL_VEHICLE_FAILURE:
		case VehicleActionTypes.CREATE_VEHICLE_FAILURE:
		case VehicleActionTypes.UPDATE_VEHICLE_FAILURE:
		case VehicleActionTypes.DELETE_VEHICLE_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case VehicleActionTypes.CREATE_VEHICLE_SUCCESS: 
		case VehicleActionTypes.UPDATE_VEHICLE_SUCCESS: 
		case VehicleActionTypes.DELETE_VEHICLE_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case VehicleActionTypes.FETCH_ALL_VEHICLE_SUCCESS: {
			return {
				...state,
				allVehicle: action.payload,
				loading: false,
			};
		}
		default:
			return state;
	}
};

export default vehicleReducer;
