import { SERVER_ENV } from "../../configs/env";

const KeycloakUser = async (token) => {
	let baseUrl = process.env.REACT_APP_KEYCLOAK_BASE_URL;
	console.log(`getUserInfo from ${baseUrl}`);
	const res = await fetch(
		`${baseUrl}/realms/semai-x/protocol/openid-connect/userinfo`,
		{
			method: "get",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	const res_obj = await res.json();
	console.log(`got response from getUserInfo`);
	return res_obj;
};

export default KeycloakUser;
