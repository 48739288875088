const MonthlyActivityPlanActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch ALL USERS
	FETCH_ALL_MONTHLY_ACTIVITY_PLAN_START: "FETCH_ALL_MONTHLY_ACTIVITY_PLAN_START",
	FETCH_ALL_MONTHLY_ACTIVITY_PLAN_SUCCESS: "FETCH_ALL_MONTHLY_ACTIVITY_PLAN_SUCCESS",
	FETCH_ALL_MONTHLY_ACTIVITY_PLAN_FAILURE: "FETCH_ALL_MONTHLY_ACTIVITY_PLAN_FAILURE",

	CREATE_MONTHLY_ACTIVITY_PLAN_START: "CREATE_MONTHLY_ACTIVITY_PLAN_START",
	CREATE_MONTHLY_ACTIVITY_PLAN_SUCCESS: "CREATE_MONTHLY_ACTIVITY_PLAN_SUCCESS",
	CREATE_MONTHLY_ACTIVITY_PLAN_FAILURE: "CREATE_MONTHLY_ACTIVITY_PLAN_FAILURE",

	UPDATE_MONTHLY_ACTIVITY_PLAN_START: "UPDATE_MONTHLY_ACTIVITY_PLAN_START",
	UPDATE_MONTHLY_ACTIVITY_PLAN_SUCCESS: "UPDATE_MONTHLY_ACTIVITY_PLAN_SUCCESS",
	UPDATE_MONTHLY_ACTIVITY_PLAN_FAILURE: "UPDATE_MONTHLY_ACTIVITY_PLAN_FAILURE",
	
	DELETE_MONTHLY_ACTIVITY_PLAN_START: "DELETE_MONTHLY_ACTIVITY_PLAN_START",
	DELETE_MONTHLY_ACTIVITY_PLAN_SUCCESS: "DELETE_MONTHLY_ACTIVITY_PLAN_SUCCESS",
	DELETE_MONTHLY_ACTIVITY_PLAN_FAILURE: "DELETE_MONTHLY_ACTIVITY_PLAN_FAILURE",
};

export default MonthlyActivityPlanActionTypes;
