import ActivitiesActionTypes from "./logging-angkut.types";

export const fetchAngkutStart = (username) => ({
	type: ActivitiesActionTypes.FETCH_ANGKUT_START,
	payload: username,
});

export const fetchAngkutSuccess = (company) => ({
	type: ActivitiesActionTypes.FETCH_ANGKUT_SUCCESS,
	payload: company,
});

export const fetchAngkutFailure = (error) => ({
	type: ActivitiesActionTypes.FETCH_ANGKUT_FAILURE,
	payload: error,
});

export const clearAngkutError = () => ({
	type: ActivitiesActionTypes.CLEAR_ERROR,
});
