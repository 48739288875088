const ActivityPlanActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch ACTIVITIES
	FETCH_ACTIVITIES_PLAN_START: "FETCH_ACTIVITIES_PLAN_START",
	FETCH_ACTIVITIES_PLAN_SUCCESS: "FETCH_ACTIVITIES_PLAN_SUCCESS",
	FETCH_ACTIVITIES_PLAN_FAILURE: "FETCH_ACTIVITIES_PLAN_FAILURE",
	// CREATE ACTIVITIES
	CREATE_ACTIVITIES_PLAN_START: "CREATE_ACTIVITIES_PLAN_START",
	CREATE_ACTIVITIES_PLAN_SUCCESS: "CREATE_ACTIVITIES_PLAN_SUCCESS",
	CREATE_ACTIVITIES_PLAN_FAILURE: "CREATE_ACTIVITIES_PLAN_FAILURE",
	// UPDATE ACTIVITIES
	UPDATE_ACTIVITIES_PLAN_START: "UPDATE_ACTIVITIES_PLAN_START",
	UPDATE_ACTIVITIES_PLAN_SUCCESS: "UPDATE_ACTIVITIES_PLAN_SUCCESS",
	UPDATE_ACTIVITIES_PLAN_FAILURE: "UPDATE_ACTIVITIES_PLAN_FAILURE",
};

export default ActivityPlanActionTypes;
