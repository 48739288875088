import axios from "axios";
import moment from "moment";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BACKEND_BASE_URL } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import { selectCurrentUser } from "../user/user.selector";
import {
	clearVehicleError,
	fetchAllVehicleStart,
	fetchAllVehicleSuccess,
	fetchAllVehicleFailure,
	createVehicleSuccess,
	createVehicleFailure,
	deleteVehicleSuccess,
	deleteVehicleFailure,
	updateVehicleSuccess,
	updateVehicleFailure,
} from "./vehicle.action";
import VehicleActionTypes from "./vehicle.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";

export function* fetchAllVehicle() {
	const company = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltCompany: company.id,
		fltDeleted: 0,
		pageSize: 0,
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BACKEND_BASE_URL}/vehicles?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const vehicles = response.data.records;

		yield put(fetchAllVehicleSuccess(vehicles));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearVehicleError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAllVehicleFailure(errorMessage));
	}
}

export function* onFetchAllVehicleStart() {
	yield takeLatest(
		VehicleActionTypes.FETCH_ALL_VEHICLE_START, fetchAllVehicle
	);
}

export function* createVehicle({ payload }) {
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	const info = payload;
	try {
		const uuid = yield axios({
			method: "GET",
			url: "https://www.uuidgenerator.net/api/version1",
		});

		const response = yield axios({
			method: "POST",
			url: `${BACKEND_BASE_URL}/vehicles`,
			data: {
				acquisitionYear: info.acquisitionYear,
				afdelingId: !!info.afdelingId ? info.afdelingId : undefined,
				appMode: 0,
				code: info.code,
				companyId: company.id,
				companyName: company.name,
				createdBy: currentUser.name,
				createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				deletedBy: null,
				deletedTime: null,
				description: info.description,
				engineNo: info.engineNo,
				estateId: info.estateId,
				frameNo: info.frameNo,
				id: uuid.data,
				isDeleted: 0,
				name: info.name,
				ownership: info.ownership,
				syncedStatus: 0,
				syncedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				tareWeight: info.tareWeight,
				type: info.type,
				updatedBy: currentUser.name,
				updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				version: 0
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(createVehicleSuccess());
		yield put(fetchAllVehicleStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearVehicleError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(createVehicleFailure(errorMessage));
	}
}

export function* onCreateVehicleStart() {
	yield takeLatest(
		VehicleActionTypes.CREATE_VEHICLE_START, createVehicle
	);
}

export function* deleteVehicle({ payload }) {
	const id = payload;
	try {
		const response = yield axios({
			method: "DELETE",
			url: `${BACKEND_BASE_URL}/vehicles/${id}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(deleteVehicleSuccess());
		yield put(fetchAllVehicleStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearVehicleError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(deleteVehicleFailure(errorMessage));
	}
}

export function* onDeleteVehicleStart() {
	yield takeLatest(
		VehicleActionTypes.DELETE_VEHICLE_START, deleteVehicle
	);
}

export function* updateVehicle({ payload }) {
	const { id, info, version } = payload;
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	try {
		const response = yield axios({
			method: "PUT",
			url: `${BACKEND_BASE_URL}/vehicles/${id}`,
			data: {
				acquisitionYear: info.acquisitionYear,
				afdelingId: !!info.afdelingId ? info.afdelingId : undefined,
				appMode: 0,
				code: info.code,
				companyId: company.id,
				companyName: company.name,
				createdBy: currentUser.name,
				createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				deletedBy: null,
				deletedTime: null,
				description: info.description,
				engineNo: info.engineNo,
				estateId: info.estateId,
				frameNo: info.frameNo,
				id: id,
				isDeleted: 0,
				name: info.name,
				ownership: info.ownership,
				syncedStatus: 0,
				syncedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				tareWeight: info.tareWeight,
				type: info.type,
				updatedBy: currentUser.name,
				updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				version: version,
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(updateVehicleSuccess());
		yield put(fetchAllVehicleStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearVehicleError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateVehicleFailure(errorMessage));
	}
}

export function* onUpdateVehicleStart() {
	yield takeLatest(
		VehicleActionTypes.UPDATE_VEHICLE_START, updateVehicle
	);
}

export function* vehicleSagas() {
	yield all([
		call(onFetchAllVehicleStart),
		call(onCreateVehicleStart),
		call(onDeleteVehicleStart),
		call(onUpdateVehicleStart),
	]);
}
