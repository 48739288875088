const VehicleActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch ALL VEHICLE
	FETCH_ALL_VEHICLE_START: "FETCH_ALL_VEHICLE_START",
	FETCH_ALL_VEHICLE_SUCCESS: "FETCH_ALL_VEHICLE_SUCCESS",
	FETCH_ALL_VEHICLE_FAILURE: "FETCH_ALL_VEHICLE_FAILURE",
	
    CREATE_VEHICLE_START: "CREATE_VEHICLE_START",
	CREATE_VEHICLE_SUCCESS: "CREATE_VEHICLE_SUCCESS",
	CREATE_VEHICLE_FAILURE: "CREATE_VEHICLE_FAILURE",
	
    DELETE_VEHICLE_START: "DELETE_VEHICLE_START",
	DELETE_VEHICLE_SUCCESS: "DELETE_VEHICLE_SUCCESS",
	DELETE_VEHICLE_FAILURE: "DELETE_VEHICLE_FAILURE",
	
    UPDATE_VEHICLE_START: "UPDATE_VEHICLE_START",
	UPDATE_VEHICLE_SUCCESS: "UPDATE_VEHICLE_SUCCESS",
	UPDATE_VEHICLE_FAILURE: "UPDATE_VEHICLE_FAILURE",

};

export default VehicleActionTypes;
