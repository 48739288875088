import MonthlyActivityPlanActionTypes from "./monthly-activity-plan.types";

const INITIAL_STATE = {
	error: null,
	loading: false,
	allMonthlyActivityPlan: [],
};

const monthlyActivityPlanReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MonthlyActivityPlanActionTypes.FETCH_ALL_MONTHLY_ACTIVITY_PLAN_SUCCESS:
			return {
				...state,
				allMonthlyActivityPlan: action.payload,
				loading: false,
			};
		case MonthlyActivityPlanActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case MonthlyActivityPlanActionTypes.FETCH_ALL_MONTHLY_ACTIVITY_PLAN_START:
		case MonthlyActivityPlanActionTypes.CREATE_MONTHLY_ACTIVITY_PLAN_START:
		case MonthlyActivityPlanActionTypes.UPDATE_MONTHLY_ACTIVITY_PLAN_START:
		case MonthlyActivityPlanActionTypes.DELETE_MONTHLY_ACTIVITY_PLAN_START:
			return {
				...state,
				loading: true,
			};
		case MonthlyActivityPlanActionTypes.FETCH_ALL_MONTHLY_ACTIVITY_PLAN_FAILURE:
		case MonthlyActivityPlanActionTypes.CREATE_MONTHLY_ACTIVITY_PLAN_FAILURE:
		case MonthlyActivityPlanActionTypes.UPDATE_MONTHLY_ACTIVITY_PLAN_FAILURE:
		case MonthlyActivityPlanActionTypes.DELETE_MONTHLY_ACTIVITY_PLAN_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case MonthlyActivityPlanActionTypes.CREATE_MONTHLY_ACTIVITY_PLAN_SUCCESS:
		case MonthlyActivityPlanActionTypes.UPDATE_MONTHLY_ACTIVITY_PLAN_SUCCESS:
		case MonthlyActivityPlanActionTypes.DELETE_MONTHLY_ACTIVITY_PLAN_SUCCESS:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

export default monthlyActivityPlanReducer;
