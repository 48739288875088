import axios from "axios";
import moment from "moment";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BACKEND_BASE_URL } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import { selectCurrentUser } from "../user/user.selector";
import {
	clearEstateError,
	fetchAllEstatesSuccess,
	fetchAllEstatesFailure,
	createEstateSuccess,
	createEstateFailure,
	fetchAllEstatesStart,
	updateEstateSuccess,
	updateEstateFailure,
	deleteEstateSuccess,
	deleteEstateFailure,
} from "./estate.action";
import { selectEstateSingular } from "./estate.selector";
import EstateActionTypes from "./estate.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";

export function* fetchAllEstates() {
	const company = yield select(selectCurrentUserCompany);
	const filters = queryString.stringify({
		fltCompany: company.id,
		pageSize: 0,
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BACKEND_BASE_URL}/estates?${filters}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const estates = response.data.records;

		yield put(fetchAllEstatesSuccess(estates));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearEstateError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAllEstatesFailure(errorMessage));
	}
}

export function* onFetchAllEsatesStart() {
	yield takeLatest(
		EstateActionTypes.FETCH_ALL_ESTATES_START,
		fetchAllEstates
	);
}

export function* createEstate({ payload }) {
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	const estateInfo = payload;
	try {
		const uuid = yield axios({
			method: "GET",
			url: "https://www.uuidgenerator.net/api/version1",
		});

		const response = yield axios({
			method: "POST",
			url: `${BACKEND_BASE_URL}/estates`,
			data: {
				areaHa: estateInfo.area,
				companyId: company.id,
				name: estateInfo.estateName,
				description: estateInfo.description,
				isDeleted: 0,
				createdBy: currentUser.username,
				createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				deletedBy: null,
				deletedTime: null,
				id: uuid.data,
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(createEstateSuccess());
		yield put(fetchAllEstatesStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearEstateError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(createEstateFailure(errorMessage));
	}
}

export function* onCreateEstateStart() {
	yield takeLatest(EstateActionTypes.CREATE_ESTATE_START, createEstate);
}

export function* updateEstate({ payload }) {
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	const estateInfo = payload;
	try {
		const response = yield axios({
			method: "PUT",
			url: `${BACKEND_BASE_URL}/estates/${estateInfo.id}`,
			data: {
				id: estateInfo.id,
				companyId: company.id,
				areaHa: estateInfo.area,
				name: estateInfo.estateName,
				description: estateInfo.description,
				updatedBy: currentUser.username,
				updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				version: estateInfo.version,
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(updateEstateSuccess());
		yield put(fetchAllEstatesStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearEstateError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateEstateFailure(errorMessage));
	}
}

export function* onUpdateEstateStart() {
	yield takeLatest(EstateActionTypes.UPDATE_ESTATE_START, updateEstate);
}

export function* deleteEstate({ payload }) {
	const estateId = payload;
	try {
		const response = yield axios({
			method: "DELETE",
			url: `${BACKEND_BASE_URL}/estates/${estateId}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(deleteEstateSuccess());
		yield put(fetchAllEstatesStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearEstateError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(deleteEstateFailure(errorMessage));
	}
}

export function* onDeleteEstateStart() {
	yield takeLatest(EstateActionTypes.DELETE_ESTATE_START, deleteEstate);
}

export function* estateSagas() {
	yield all([
		call(onFetchAllEsatesStart),
		call(onCreateEstateStart),
		call(onUpdateEstateStart),
		call(onDeleteEstateStart),
	]);
}
