import MonthlyActivityPlanActionTypes from "./monthly-activity-plan.types";

export const clearMonthlyActivityPlanError = () => ({
	type: MonthlyActivityPlanActionTypes.CLEAR_ERROR,
});

export const fetchAllMonthlyActivityPlanStart = (filter) => ({
	type: MonthlyActivityPlanActionTypes.FETCH_ALL_MONTHLY_ACTIVITY_PLAN_START,
	payload: filter,
});

export const fetchAllMonthlyActivityPlanSuccess = (monthlyActivityPlan) => ({
	type: MonthlyActivityPlanActionTypes.FETCH_ALL_MONTHLY_ACTIVITY_PLAN_SUCCESS,
	payload: monthlyActivityPlan,
});

export const fetchAllMonthlyActivityPlanFailure = (error) => ({
	type: MonthlyActivityPlanActionTypes.FETCH_ALL_MONTHLY_ACTIVITY_PLAN_FAILURE,
	payload: error,
});

export const createMonthlyActivityPlanStart = ({ info, filter }) => ({
	type: MonthlyActivityPlanActionTypes.CREATE_MONTHLY_ACTIVITY_PLAN_START,
	payload : { info, filter },
});

export const createMonthlyActivityPlanSuccess = () => ({
	type: MonthlyActivityPlanActionTypes.CREATE_MONTHLY_ACTIVITY_PLAN_SUCCESS,
});

export const createMonthlyActivityPlanFailure = (error) => ({
	type: MonthlyActivityPlanActionTypes.CREATE_MONTHLY_ACTIVITY_PLAN_FAILURE,
	payload: error,
});

export const updateMonthlyActivityPlanStart = ({ info, filter }) => ({
	type: MonthlyActivityPlanActionTypes.UPDATE_MONTHLY_ACTIVITY_PLAN_START,
	payload: { info, filter },
});

export const updateMonthlyActivityPlanSuccess = () => ({
	type: MonthlyActivityPlanActionTypes.UPDATE_MONTHLY_ACTIVITY_PLAN_SUCCESS,
});

export const updateMonthlyActivityPlanFailure = (error) => ({
	type: MonthlyActivityPlanActionTypes.UPDATE_MONTHLY_ACTIVITY_PLAN_FAILURE,
	payload: error,
});

export const deleteMonthlyActivityPlanStart = ({ id, filter }) => ({
	type: MonthlyActivityPlanActionTypes.DELETE_MONTHLY_ACTIVITY_PLAN_START,
	payload : { id, filter },
});

export const deleteMonthlyActivityPlanSuccess = () => ({
	type: MonthlyActivityPlanActionTypes.DELETE_MONTHLY_ACTIVITY_PLAN_SUCCESS,
});

export const deleteMonthlyActivityPlanFailure = (error) => ({
	type: MonthlyActivityPlanActionTypes.DELETE_MONTHLY_ACTIVITY_PLAN_FAILURE,
	payload: error,
});