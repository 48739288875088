import ActivitiesTypeActionTypes from "./activities-categories.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	singular: null,
	allActivitiesType: [],
};

const activitiesCategoriesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActivitiesTypeActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case ActivitiesTypeActionTypes.FETCH_ALL_ACTIVITIES_TYPE_START:
		case ActivitiesTypeActionTypes.FETCH_ACTIVITIES_TYPE_START:
		case ActivitiesTypeActionTypes.CREATE_ACTIVITIES_TYPE_START:
		case ActivitiesTypeActionTypes.UPDATE_ACTIVITIES_TYPE_START:
		case ActivitiesTypeActionTypes.DELETE_ACTIVITIES_TYPE_START:
			return {
				...state,
				loading: true,
			};
		case ActivitiesTypeActionTypes.FETCH_ALL_ACTIVITIES_TYPE_FAILURE:
		case ActivitiesTypeActionTypes.FETCH_ACTIVITIES_TYPE_FAILURE:
		case ActivitiesTypeActionTypes.CREATE_ACTIVITIES_TYPE_FAILURE:
		case ActivitiesTypeActionTypes.UPDATE_ACTIVITIES_TYPE_FAILURE:
		case ActivitiesTypeActionTypes.DELETE_ACTIVITIES_TYPE_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case ActivitiesTypeActionTypes.CREATE_ACTIVITIES_TYPE_SUCCESS: 
		case ActivitiesTypeActionTypes.UPDATE_ACTIVITIES_TYPE_SUCCESS: 
		case ActivitiesTypeActionTypes.DELETE_ACTIVITIES_TYPE_SUCCESS:{
			return {
				...state,
				loading: false,
			};
		}
		case ActivitiesTypeActionTypes.FETCH_ALL_ACTIVITIES_TYPE_SUCCESS: {
			return {
				...state,
				allActivitiesType: action.payload,
				loading: false,
			};
		}
		case ActivitiesTypeActionTypes.FETCH_ACTIVITIES_TYPE_SUCCESS: {
			return {
				...state,
				singular: action.payload,
				loading: false,
			};
		}
		default:
			return state;
	}
};

export default activitiesCategoriesReducer;
