import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./user/user.reducer";
import snackbarReducer from "./snackbar/snackbar.reducer";
import joinRequestReducer from "./join-request/join-request.reducer";
import companyReducer from "./company/company.reducer";
import memberReducer from "./member/member.reducer";
import harvesterReducer from "./harvester/harvester.reducer";
import harvestActivityReducer from "./harvest-activity/harvest-activity.reducer";
import blockReducer from "./block/block.reducer";
import activitiesCategoriesReducer from "./activities-categories/activities-categories.reducer";
import activitiesTypeReducer from "./activities-type/activities-type.reducer";
import taksasiActivityReducer from "./taksasi-activity/taksasi-activity.reducer";
import taksasiVsPanenActivityReducer from "./taksasiVsPanen-activity/taksasiVsPanen-activity.reducer";
import mutuAncakActivityReducer from "./mutu-ancak-activity/mutu-ancak-activity.reducer";
import estateReducer from "./estate/estate.reducer";
import mapsReducer from "./maps/maps.reducer";
import afdelingReducer from "./afdeling/afdeling.reducer";
import vehicleTypeReducer from "./vehicle-type/vehicle-type.reducer";
import vehicleReducer from "./vehicle/vehicle.reducer";
import monthlyActivityPlanReducer from "./monthly-activity-plan/monthly-activity-plan.reducer";
import loggingAngkutReducer from "./logging-angkut/logging-angkut.reducer";
import loggingRawatReducer from "./logging-rawat/logging-rawat.reducer";
import activitiesPlanReducer from "./activity-planning/activity-planning.reducer";
import routeReducer from "./route-planning/route-planning.reducer";

const persistConfig = {
	key: "root",
	storage,
	blacklist: [
		"semaiUser",
		"snackbar",
		"joinRequest",
		"company",
		"member",
		"harvester",
		"harvestActivity",
		"block",
		"activitiesCategories",
		"activitiesType",
		"taksasiActivity",
		"taksasiVsPanenActivity",
		"mutuAncakActivity",
		"estate",
		"maps",
		"mapsPopUp",
		"afdeling",
		"vehicleType",
		"vehicle",
		"monthlyActivityPlan",
		"loggingAngkut",
		"loggingRawat",
		"activitiesPlan",
		"routePlan",
	],
};

const userPersistConfig = {
	key: "semaiUser",
	storage,
	whitelist: ["currentUser", "token", "provider", "refreshToken"],
};

const rootReducer = combineReducers({
	semaiUser: persistReducer(userPersistConfig, userReducer),
	snackbar: snackbarReducer,
	joinRequest: joinRequestReducer,
	company: companyReducer,
	member: memberReducer,
	activitiesCategories: activitiesCategoriesReducer,
	activitiesType: activitiesTypeReducer,
	harvester: harvesterReducer,
	harvestActivity: harvestActivityReducer,
	block: blockReducer,
	taksasiActivity: taksasiActivityReducer,
	mutuAncakActivity: mutuAncakActivityReducer,
	afdeling : afdelingReducer,
	taksasiVsPanenActivity: taksasiVsPanenActivityReducer,
	estate: estateReducer,
	maps: mapsReducer,
	vehicleType: vehicleTypeReducer,
	vehicle: vehicleReducer,
	monthlyActivityPlan: monthlyActivityPlanReducer,
	loggingAngkut: loggingAngkutReducer,
	loggingRawat: loggingRawatReducer,
	activitiesPlan : activitiesPlanReducer,
	routePlan: routeReducer
});

export default persistReducer(persistConfig, rootReducer);
