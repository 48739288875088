import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BACKEND_BASE_URL } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import {
	clearTaksasiVsPanenActivityError,
	fetchTaksasiVsPanenSuccess,
	fetchTaksasiVsPanenFailure,
} from "./taksasiVsPanen-activity.action";
import TaksasiVsPanenActivityActionTypes from "./taksasiVsPanen-activity.types";
import queryString from "query-string";

export function* fetchTaksasiVsPanen({ payload }) {
	const filters = payload;
	const query = queryString.stringify({
		pageSize: 0,
	});

	try {
		const response = yield axios({
			method: "GET",
			url: `${BACKEND_BASE_URL}/harvesting-estimate-vs-actual/by-${filters.filterLevel}/${filters.id}/${filters.year}/${filters.month}/${filters.day}?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const harvestTaksasi = response.data.records;
		yield put(fetchTaksasiVsPanenSuccess(harvestTaksasi));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearTaksasiVsPanenActivityError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchTaksasiVsPanenFailure(errorMessage));
	}
}

export function* onFetchTaksasiVsPanenStart() {
	yield takeLatest(
		TaksasiVsPanenActivityActionTypes.FETCH_TAKSASI_VS_PANEN_START,
		fetchTaksasiVsPanen
	);
}

export function* taksasiVsPanenActivitySagas() {
	yield all([call(onFetchTaksasiVsPanenStart)]);
}
