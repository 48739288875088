import ActivitiesTypeActionTypes from "./activities-categories.types";

export const fetchAllActivitiesTypeStart = () => ({
	type: ActivitiesTypeActionTypes.FETCH_ALL_ACTIVITIES_TYPE_START,
});

export const fetchAllActivitiesTypeSuccess = (allActivitiesType) => ({
	type: ActivitiesTypeActionTypes.FETCH_ALL_ACTIVITIES_TYPE_SUCCESS,
	payload: allActivitiesType,
});

export const fetchAllActivitiesTypeFailure = (error) => ({
	type: ActivitiesTypeActionTypes.FETCH_ALL_ACTIVITIES_TYPE_FAILURE,
	payload: error,
});

export const fetchActivitiesTypeStart = (username) => ({
	type: ActivitiesTypeActionTypes.FETCH_ACTIVITIES_TYPE_START,
	payload: username,
});

export const fetchActivitiesTypeSuccess = (company) => ({
	type: ActivitiesTypeActionTypes.FETCH_ACTIVITIES_TYPE_SUCCESS,
	payload: company,
});

export const fetchActivitiesTypeFailure = (error) => ({
	type: ActivitiesTypeActionTypes.FETCH_ACTIVITIES_TYPE_FAILURE,
	payload: error,
});

export const createActivitiesTypeStart = (info) => ({
	type: ActivitiesTypeActionTypes.CREATE_ACTIVITIES_TYPE_START,
	payload : info
});

export const createActivitiesTypeSuccess = () => ({
	type: ActivitiesTypeActionTypes.CREATE_ACTIVITIES_TYPE_SUCCESS,
});

export const createActivitiesTypeFailure = (error) => ({
	type: ActivitiesTypeActionTypes.CREATE_ACTIVITIES_TYPE_FAILURE,
	payload: error,
});

export const updateActivitiesTypeStart = ({ id , info, version }) => ({
	type: ActivitiesTypeActionTypes.UPDATE_ACTIVITIES_TYPE_START,
	payload: { id , info, version},
});

export const updateActivitiesTypeSuccess = () => ({
	type: ActivitiesTypeActionTypes.UPDATE_ACTIVITIES_TYPE_SUCCESS,
});

export const updateActivitiesTypeFailure = (error) => ({
	type: ActivitiesTypeActionTypes.UPDATE_ACTIVITIES_TYPE_FAILURE,
	payload: error,
});

export const deleteActivitiesTypeStart = ( id ) => ({
	type: ActivitiesTypeActionTypes.DELETE_ACTIVITIES_TYPE_START,
	payload: id,
});

export const deleteActivitiesTypeSuccess = () => ({
	type: ActivitiesTypeActionTypes.DELETE_ACTIVITIES_TYPE_SUCCESS,
});

export const deleteActivitiesTypeFailure = (error) => ({
	type: ActivitiesTypeActionTypes.DELETE_ACTIVITIES_TYPE_FAILURE,
	payload: error,
});

export const clearActivitiesTypeError = () => ({
	type: ActivitiesTypeActionTypes.CLEAR_ERROR,
});
