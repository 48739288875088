import UserActionTypes from "./user.types";

export const checkUserSession = () => ({
	type: UserActionTypes.CHECK_USER_SESSION,
});

export const fetchAllUsersStart = () => ({
	type: UserActionTypes.FETCH_ALL_USERS_START,
});

export const fetchAllUsersSuccess = (allUsers) => ({
	type: UserActionTypes.FETCH_ALL_USERS_SUCCESS,
	payload: allUsers,
});

export const fetchAllUsersFailure = (error) => ({
	type: UserActionTypes.FETCH_ALL_USERS_FAILURE,
	payload: error,
});

export const fetchUserStart = (userId) => ({
	type: UserActionTypes.FETCH_USER_START,
	payload: userId,
});

export const fetchUserSuccess = (user) => ({
	type: UserActionTypes.FETCH_USER_SUCCESS,
	payload: user,
});

export const fetchUserFailure = (error) => ({
	type: UserActionTypes.FETCH_USER_FAILURE,
	payload: error,
});

export const setRememberMe = (rememberMe) => ({
	type: UserActionTypes.SET_REMEMBER_ME,
	payload: rememberMe,
});

export const clearUserError = () => ({
	type: UserActionTypes.CLEAR_ERROR,
});

export const getAccessTokenStart = ({ code, provider }) => ({
	type: UserActionTypes.GET_ACCESS_TOKEN_START,
	payload: { code, provider },
});

export const getAccessTokenSuccess = ({
	accessToken,
	idToken,
	provider,
	refreshToken,
}) => ({
	type: UserActionTypes.GET_ACCESS_TOKEN_SUCCESS,
	payload: { accessToken, idToken, provider, refreshToken },
});

export const getAccessTokenFailure = (error) => ({
	type: UserActionTypes.FETCH_USER_FAILURE,
	payload: error,
});

export const logoutStart = () => ({
	type: UserActionTypes.USER_LOGOUT_START,
});

export const logoutSuccess = () => ({
	type: UserActionTypes.USER_LOGOUT_SUCCESS,
});

export const logoutFailure = (error) => ({
	type: UserActionTypes.USER_LOGOUT_FAILURE,
	payload: error,
});

export const checkAccessTokenStart = ({ accessToken, provider }) => ({
	type: UserActionTypes.CHECK_ACCESS_TOKEN_START,
	payload: { accessToken, provider },
});

export const checkAccessTokenSuccess = () => ({
	type: UserActionTypes.CHECK_ACCESS_TOKEN_SUCCESS,
});

export const checkAccessTokenFailure = (error) => ({
	type: UserActionTypes.CHECK_ACCESS_TOKEN_FAILURE,
	payload: error,
});

export const refreshAccessTokenStart = (provider) => ({
	type: UserActionTypes.REFRESH_ACCESS_TOKEN_START,
	payload: provider,
});

export const refreshAccessTokenSuccess = ({ accessToken, refreshToken }) => ({
	type: UserActionTypes.REFRESH_ACCESS_TOKEN_SUCCESS,
	payload: { accessToken, refreshToken },
});

export const refreshAccessTokenFailure = (error) => ({
	type: UserActionTypes.REFRESH_ACCESS_TOKEN_FAILURE,
	payload: error,
});

export const forgotPasswordStart = ({ email, history }) => ({
	type: UserActionTypes.FORGOT_PASS_START,
	payload: { email, history },
});

export const forgotPasswordSuccess = () => ({
	type: UserActionTypes.FORGOT_PASS_SUCCESS,
});

export const forgotPasswordFailure = (error) => ({
	type: UserActionTypes.FORGOT_PASS_FAILURE,
	payload: error,
});

export const registerUserStart = ({ userInfo, history }) => ({
	type: UserActionTypes.REGISTER_USER_START,
	payload: { userInfo, history },
});

export const registerUserSuccess = () => ({
	type: UserActionTypes.REGISTER_USER_SUCCESS,
});

export const registerUserFailure = (error) => ({
	type: UserActionTypes.REGISTER_USER_FAILURE,
	payload: error,
});

export const updateUserStart = (userInfo) => ({
	type: UserActionTypes.UPDATE_USER_START,
	payload: userInfo,
});

export const updateUserSuccess = () => ({
	type: UserActionTypes.UPDATE_USER_SUCCESS,
});

export const updateUserFailure = (error) => ({
	type: UserActionTypes.UPDATE_USER_FAILURE,
	payload: error,
});

export const resetUserPasswordStart = (data) => ({
	type: UserActionTypes.RESET_USER_PASSWORD_START,
	payload: data,
});

export const resetUserPasswordFailure = (error) => ({
	type: UserActionTypes.RESET_USER_PASSWORD_FAILURE,
	payload: error,
});

export const insertUserStart = (userInfo) => ({
	type: UserActionTypes.INSERT_USER_START,
	payload: userInfo,
});

export const insertUserSuccess = () => ({
	type: UserActionTypes.INSERT_USER_SUCCESS,
});

export const insertUserFailure = (error) => ({
	type: UserActionTypes.INSERT_USER_FAILURE,
	payload: error,
});

export const importUsersStart = (importInfo) => ({
	type: UserActionTypes.IMPORT_USERS_START,
	payload: importInfo,
});

export const importUsersSuccess = () => ({
	type: UserActionTypes.IMPORT_USERS_SUCCESS,
});

export const importUsersFailure = (error) => ({
	type: UserActionTypes.IMPORT_USERS_FAILURE,
	payload: error,
});

export const deleteUserStart = (userId) => ({
	type: UserActionTypes.DELETE_USER_START,
	payload: userId,
});

export const deleteUserSuccess = () => ({
	type: UserActionTypes.DELETE_USER_SUCCESS,
});

export const deleteUserFailure = (error) => ({
	type: UserActionTypes.DELETE_USER_FAILURE,
	payload: error,
});

export const setUserRole = (role) => ({
	type: UserActionTypes.SET_USER_ROLE,
	payload: role,
});
