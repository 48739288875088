import ActivitiesActionTypes from "./logging-angkut.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	angkut: [],
};

const loggingAngkutReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActivitiesActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case ActivitiesActionTypes.FETCH_ANGKUT_START:
			return {
				...state,
				loading: true,
			};
		case ActivitiesActionTypes.FETCH_ANGKUT_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case ActivitiesActionTypes.FETCH_ANGKUT_SUCCESS: {
			return {
				...state,
				angkut: action.payload,
				loading: false,
			};
		}
		default:
			return state;
	}
};

export default loggingAngkutReducer;
