import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { openSnackbar } from "../snackbar/snackbar.action";
import { selectCurrentUser } from "../user/user.selector";
import {
	clearJoinRequestError,
	fetchJoinRequestsFailure,
	fetchJoinRequestsSuccess,
	acceptJoinRequestSuccess,
	acceptJoinRequestFailure,
	fetchJoinRequestsStart,
	rejectJoinRequestSuccess,
	rejectJoinRequestFailure,
} from "./join-request.action";
import JoinRequestActionTypes from "./join-request.types";
import queryString from "query-string";
import { BACKEND_BASE_URL } from "../../configs/env";
import { selectCurrentUserCompany } from "../company/company.selector";

export function* fetchJoinRequests() {
	const { username } = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltCompany: company.id,
		fltDeleted: 0,
		fltStatus: 0,
		pageSize: 0,
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BACKEND_BASE_URL}/companies/join-requests?${query}`,
			headers: {},
		});
		if (!response.data.success) {
			throw new Error(response.data.message);
		}
		const joinRequests = response.data.records;

		yield put(fetchJoinRequestsSuccess(joinRequests));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearJoinRequestError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchJoinRequestsFailure(errorMessage));
	}
}

export function* onFetchJoinRequestStart() {
	yield takeLatest(
		JoinRequestActionTypes.FETCH_JOIN_REQUESTS_START,
		fetchJoinRequests
	);
}

export function* acceptJoinRequest({ payload }) {
	const { id, defaultEstate, defaultAfdeling } = payload;
	const data = queryString.stringify({
		estateId: defaultEstate,
		afdelingId: defaultAfdeling,
	});
	try {
		const response = yield axios({
			method: "PUT",
			url: `${BACKEND_BASE_URL}/companies/join-requests/${id}`,
			data,
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(acceptJoinRequestSuccess());
		yield put(fetchJoinRequestsStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearJoinRequestError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(acceptJoinRequestFailure(errorMessage));
	}
}

export function* onAcceptJoinRequestStart() {
	yield takeLatest(
		JoinRequestActionTypes.ACCEPT_JOIN_REQUEST_START,
		acceptJoinRequest
	);
}

export function* rejectJoinRequest({ payload }) {
	const requestId = payload;
	try {
		const response = yield axios({
			method: "DELETE",
			url: `${BACKEND_BASE_URL}/companies/join-requests/${requestId}`,
		});
		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(rejectJoinRequestSuccess());
		yield put(fetchJoinRequestsStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearJoinRequestError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(rejectJoinRequestFailure(errorMessage));
	}
}

export function* onRejectJoinRequestStart() {
	yield takeLatest(
		JoinRequestActionTypes.REJECT_JOIN_REQUEST_START,
		rejectJoinRequest
	);
}

export function* joinRequestSagas() {
	yield all([
		call(onFetchJoinRequestStart),
		call(onAcceptJoinRequestStart),
		call(onRejectJoinRequestStart),
	]);
}
