import BlockActionTypes from "./block.types";

export const fetchBlocksStart = (filters) => ({
	type: BlockActionTypes.FETCH_BLOCKS_START,
	payload: filters,
});

export const fetchBlocksSuccess = (blocks) => ({
	type: BlockActionTypes.FETCH_BLOCKS_SUCCESS,
	payload: blocks,
});

export const fetchBlocksFailure = (error) => ({
	type: BlockActionTypes.FETCH_BLOCKS_FAILURE,
	payload: error,
});

export const fetchBlocksByIdStart = (companyId) => ({
	type: BlockActionTypes.FETCH_BLOCKS_BY_ID_START,
	payload: companyId,
});

export const fetchBlocksByIdSuccess = (blocks) => ({
	type: BlockActionTypes.FETCH_BLOCKS_BY_ID_SUCCESS,
	payload: blocks,
});

export const fetchBlocksByIdFailure = (error) => ({
	type: BlockActionTypes.FETCH_BLOCKS_BY_ID_FAILURE,
	payload: error,
});

export const fetchBlocksByCompanyIdStart = (companyId) => ({
	type: BlockActionTypes.FETCH_BLOCKS_BY_COMPANY_ID_START,
	payload: companyId,
});

export const fetchBlocksByCompanyIdSuccess = (blocks) => ({
	type: BlockActionTypes.FETCH_BLOCKS_BY_COMPANY_ID_SUCCESS,
	payload: blocks,
});

export const fetchBlocksByCompanyIdFailure = (error) => ({
	type: BlockActionTypes.FETCH_BLOCKS_BY_COMPANY_ID_FAILURE,
	payload: error,
});

export const registerBlockStart = ( info ) => ({
	type: BlockActionTypes.REGISTER_BLOCKS_START,
	payload: info ,
});

export const registerBlockSuccess = () => ({
	type: BlockActionTypes.REGISTER_BLOCKS_SUCCESS,
});

export const registerBlockFailure = (error) => ({
	type: BlockActionTypes.REGISTER_BLOCKS_FAILURE,
	payload: error,
});

export const clearBlockError = () => ({
	type: BlockActionTypes.CLEAR_ERROR,
});

export const updateBlocksStart = ({ id , info, version }) => ({
	type: BlockActionTypes.UPDATE_BLOCKS_START,
	payload: { id , info, version },
});

export const updateBlocksSuccess = () => ({
	type: BlockActionTypes.UPDATE_BLOCKS_SUCCESS,
});

export const updateBlocksFailure = (error) => ({
	type: BlockActionTypes.UPDATE_BLOCKS_FAILURE,
	payload: error,
});

export const importBlocksStart = (importInfo) => ({
	type: BlockActionTypes.IMPORT_BLOCKS_START,
	payload: importInfo,
});

export const importBlocksSuccess = () => ({
	type: BlockActionTypes.IMPORT_BLOCKS_SUCCESS,
});

export const importBlocksFailure = (error) => ({
	type: BlockActionTypes.IMPORT_BLOCKS_FAILURE,
	payload: error,
});

export const deleteBlocksStart = ( id ) => ({
	type: BlockActionTypes.DELETE_BLOCKS_START,
	payload: id ,
});

export const deleteBlocksSuccess = () => ({
	type: BlockActionTypes.DELETE_BLOCKS_SUCCESS,
});

export const deleteBlocksFailure = (error) => ({
	type: BlockActionTypes.DELETE_BLOCKS_FAILURE,
	payload: error,
});