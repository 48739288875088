const ActivitiesTypeActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch ALL ACTIVITIES_TYPE
	FETCH_ALL_ACTIVITIES_TYPE_START: "FETCH_ALL_ACTIVITIES_TYPE_START",
	FETCH_ALL_ACTIVITIES_TYPE_SUCCESS: "FETCH_ALL_ACTIVITIES_TYPE_SUCCESS",
	FETCH_ALL_ACTIVITIES_TYPE_FAILURE: "FETCH_ALL_ACTIVITIES_TYPE_FAILURE",
	// Fetch ACTIVITIES_TYPE
	FETCH_ACTIVITIES_TYPE_START: "FETCH_ACTIVITIES_TYPE_START",
	FETCH_ACTIVITIES_TYPE_SUCCESS: "FETCH_ACTIVITIES_TYPE_SUCCESS",
	FETCH_ACTIVITIES_TYPE_FAILURE: "FETCH_ACTIVITIES_TYPE_FAILURE",
	// CREATE ACTIVITIES_TYPE
	CREATE_ACTIVITIES_TYPE_START: "CREATE_ACTIVITIES_TYPE_START",
	CREATE_ACTIVITIES_TYPE_SUCCESS: "CREATE_ACTIVITIES_TYPE_SUCCESS",
	CREATE_ACTIVITIES_TYPE_FAILURE: "CREATE_ACTIVITIES_TYPE_FAILURE",
	// Update ACTIVITIES_TYPE
	UPDATE_ACTIVITIES_TYPE_START: "UPDATE_ACTIVITIES_TYPE_START",
	UPDATE_ACTIVITIES_TYPE_SUCCESS: "UPDATE_ACTIVITIES_TYPE_SUCCESS",
	UPDATE_ACTIVITIES_TYPE_FAILURE: "UPDATE_ACTIVITIES_TYPE_FAILURE",
	// Delete ACTIVITIES_TYPE
	DELETE_ACTIVITIES_TYPE_START: "DELETE_ACTIVITIES_TYPE_START",
	DELETE_ACTIVITIES_TYPE_SUCCESS: "DELETE_ACTIVITIES_TYPE_SUCCESS",
	DELETE_ACTIVITIES_TYPE_FAILURE: "DELETE_AFDELING_FAILURE",
};

export default ActivitiesTypeActionTypes;
