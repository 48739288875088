import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	headerContainer: {
		padding: "24px",
		background: "#FFFFFF",
		boxShadow: "0px 4px 10px rgba(91, 83, 83, 0.25)",
		minHeight: "100px",
		maxHeight: "100px",
		position: "fixed",
		top: "0",
		zIndex: 1,
		transition: "all 0.7s cubic-bezier(0.19, 1, 0.22, 1)",
		overflow: "hidden",
	},
	icon: {
		width: "32px",
		height: "32px",
		marginLeft: "12px",
		marginRight: "12px",
		cursor: "pointer",

		[theme.breakpoints.down("sm")]: {
			width: "24px",
			height: "24px",
		},
	},
	menu: {
		marginTop: "48px",
	},
	semaiLogo: {
		width: "180px",
		cursor: "pointer",
	},
}));
