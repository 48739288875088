// return entity level
export const entityLevelOrders = [
	"company",
	"estate",
	"afdeling",
	"block",
	"tree",
];

export const entityLevelOrderPlural = [
	"companies",
	"estates",
	"afdelings",
	"blocks",
	"trees",
];

export const generateGeoJSON = ({ data }) => {
	let initialPoint = [112.997777999999997, -1.80299999999999994];

	let features = [];

	
		let geometry = {
			type: 'LineString',
			coordinates: [],
		};
		if(!!data){
		data.forEach((data) => {
			geometry.coordinates.push([data.longitude, data.latitude]);
		});
		}
		let feature = {
			type: "Feature",
			properties: {
				...data,
			},
			geometry,
		};

		features.push(feature);
	

	const geoJSON = {
		type: "FeatureCollection",
		features: features,
	};

	return geoJSON;
};
