import ActivitiesActionTypes from "./logging-rawat.types";

export const fetchRawatStart = (username) => ({
	type: ActivitiesActionTypes.FETCH_RAWAT_START,
	payload: username,
});

export const fetchRawatSuccess = (company) => ({
	type: ActivitiesActionTypes.FETCH_RAWAT_SUCCESS,
	payload: company,
});

export const fetchRawatFailure = (error) => ({
	type: ActivitiesActionTypes.FETCH_RAWAT_FAILURE,
	payload: error,
});

export const clearRawatError = () => ({
	type: ActivitiesActionTypes.CLEAR_ERROR,
});
