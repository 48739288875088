import axios from "axios";
import moment from "moment";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BACKEND_BASE_URL } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import { selectCurrentUser } from "../user/user.selector";
import {
	clearRouteError,
	fetchRoutePlanSuccess,
	fetchRoutePlanFailure,
	createRoutePlanSuccess,
	createRoutePlanFailure,
	fetchRoutePlanStart,
} from "./route-planning.action";
import RoutePlanActionTypes from "./route-planning.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";

export function* fetchRoute() {
	const company = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltCompany: company.id,
		fltDeleted: 0,
		pageSize: 0,
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BACKEND_BASE_URL}/activity-plans?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const activities = response.data.records;

		yield put(fetchRoutePlanSuccess(activities));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearRouteError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchRoutePlanFailure(errorMessage));
	}
}

export function* onFetchRouteStart() {
	yield takeLatest(
		RoutePlanActionTypes.FETCH_ROUTE_PLAN_START,
		fetchRoute
	);
}


export function* createRoutePlan({ payload }) {
	const currentUser = yield select(selectCurrentUser);
	const activitiesInfo = payload;
	
	try {
		
		const response = yield axios({
			method: "POST",
			url: `${BACKEND_BASE_URL}/route-plannings`,
			data: {
				activityPlanId: activitiesInfo.activityPlan,
				blockId: activitiesInfo.block,
				collectionPoint: activitiesInfo.tph,
				isDeleted: 0,
				planningDate: moment(activitiesInfo.planDate).format("YYYY-MM-DD"),
				truckCode: activitiesInfo.vehicle,
				createdBy: currentUser.username,
				createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				deletedBy: null,
				deletedTime: null,
				userId: currentUser.id,
				username:currentUser.username,
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}
		const snackbarData = {
			message: "Rencana Rute Berhasil Dimasukan",
			color: "success",
			place: "bc",
			dispatchActions: [clearRouteError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(createRoutePlanSuccess());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearRouteError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(createRoutePlanFailure(errorMessage));
	}
}

export function* onCreateRouteStart() {
	yield takeLatest(RoutePlanActionTypes.CREATE_ROUTE_PLAN_START, createRoutePlan);
}


export function* routePlanningSagas() {
	yield all([
		call(onFetchRouteStart),
		call(onCreateRouteStart),
	]);
}
