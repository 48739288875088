import MapsActionTypes from "./maps.types";

export const setGeoJSON = (geoJSON) => ({
	type: MapsActionTypes.SET_GEO_JSON,
	payload: geoJSON,
});

export const clearMapsError = () => ({
	type: MapsActionTypes.CLEAR_ERROR,
});

export const setTruck = (block) => ({
	type: MapsActionTypes.SET_BLOCK,
	payload: block,
});

export const setStartDate = (startDate) => ({
	type: MapsActionTypes.SET_START_DATE,
	payload: startDate,
});

export const fetchTrucksStart = (info) => ({
	type: MapsActionTypes.FETCH_TRUCKS_START,
	payload: info,
});

export const fetchTrucksSuccess = (trucks) => ({
	type: MapsActionTypes.FETCH_TRUCKS_SUCCESS,
	payload: trucks,
});

export const fetchTrucksFailure = (error) => ({
	type: MapsActionTypes.FETCH_TRUCKS_FAILURE,
	payload: error,
});

export const fetchTracksStart = ({info}) => ({
	type: MapsActionTypes.FETCH_TRACKS_START,
	payload: {info},
});

export const fetchTracksSuccess = (blocks) => ({
	type: MapsActionTypes.FETCH_TRACKS_SUCCESS,
	payload: blocks,
});

export const fetchTracksFailure = (error) => ({
	type: MapsActionTypes.FETCH_TRACKS_FAILURE,
	payload: error,
});

export const resetMapsFilter = () => ({
	type: MapsActionTypes.RESET_MAPS_FILTER,
});

