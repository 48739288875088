const VehicleTypeActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch ALL VEHICLE_TYPE
	FETCH_ALL_VEHICLE_TYPE_START: "FETCH_ALL_VEHICLE_TYPE_START",
	FETCH_ALL_VEHICLE_TYPE_SUCCESS: "FETCH_ALL_VEHICLE_TYPE_SUCCESS",
	FETCH_ALL_VEHICLE_TYPE_FAILURE: "FETCH_ALL_VEHICLE_TYPE_FAILURE",
	// CREATE VEHICLE_TYPE
	CREATE_VEHICLE_TYPE_START: "CREATE_VEHICLE_TYPE_START",
	CREATE_VEHICLE_TYPE_SUCCESS: "CREATE_VEHICLE_TYPE_SUCCESS",
	CREATE_VEHICLE_TYPE_FAILURE: "CREATE_VEHICLE_TYPE_FAILURE",
	// Update VEHICLE_TYPE
	UPDATE_VEHICLE_TYPE_START: "UPDATE_VEHICLE_TYPE_START",
	UPDATE_VEHICLE_TYPE_SUCCESS: "UPDATE_VEHICLE_TYPE_SUCCESS",
	UPDATE_VEHICLE_TYPE_FAILURE: "UPDATE_VEHICLE_TYPE_FAILURE",
	// Delete VEHICLE_TYPE
	DELETE_VEHICLE_TYPE_START: "DELETE_VEHICLE_TYPE_START",
	DELETE_VEHICLE_TYPE_SUCCESS: "DELETE_VEHICLE_TYPE_SUCCESS",
	DELETE_VEHICLE_TYPE_FAILURE: "DELETE_VEHICLE_TYPE_FAILURE",
};

export default VehicleTypeActionTypes;
