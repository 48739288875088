import VehicleActionTypes from "./vehicle.types";

export const clearVehicleError = () => ({
	type: VehicleActionTypes.CLEAR_ERROR,
});

export const fetchAllVehicleStart = () => ({
	type: VehicleActionTypes.FETCH_ALL_VEHICLE_START,
});

export const fetchAllVehicleSuccess = (allVehicle) => ({
    type: VehicleActionTypes.FETCH_ALL_VEHICLE_SUCCESS,
	payload: allVehicle,
});

export const fetchAllVehicleFailure = (error) => ({
    type: VehicleActionTypes.FETCH_ALL_VEHICLE_FAILURE,
	payload: error,
});

export const createVehicleStart = (info) => ({
	type: VehicleActionTypes.CREATE_VEHICLE_START,
	payload : info
});

export const createVehicleSuccess = () => ({
	type: VehicleActionTypes.CREATE_VEHICLE_SUCCESS,
});

export const createVehicleFailure = (error) => ({
	type: VehicleActionTypes.CREATE_VEHICLE_FAILURE,
	payload: error,
});

export const deleteVehicleStart = (info) => ({
	type: VehicleActionTypes.DELETE_VEHICLE_START,
	payload : info
});

export const deleteVehicleSuccess = () => ({
	type: VehicleActionTypes.DELETE_VEHICLE_SUCCESS,
});

export const deleteVehicleFailure = (error) => ({
	type: VehicleActionTypes.DELETE_VEHICLE_FAILURE,
	payload: error,
});

export const updateVehicleStart = ({ id , info, version }) => ({
	type: VehicleActionTypes.UPDATE_VEHICLE_START,
	payload: { id , info, version},
});

export const updateVehicleSuccess = () => ({
	type: VehicleActionTypes.UPDATE_VEHICLE_SUCCESS,
});

export const updateVehicleFailure = (error) => ({
	type: VehicleActionTypes.UPDATE_VEHICLE_FAILURE,
	payload: error,
});