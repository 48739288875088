import RoutePlanActionTypes from "./route-planning.types";

export const fetchRoutePlanStart = (username) => ({
	type: RoutePlanActionTypes.FETCH_ROUTE_PLAN_START,
	payload: username,
});

export const fetchRoutePlanSuccess = (company) => ({
	type: RoutePlanActionTypes.FETCH_ROUTE_PLAN_SUCCESS,
	payload: company,
});

export const fetchRoutePlanFailure = (error) => ({
	type: RoutePlanActionTypes.FETCH_ROUTE_PLAN_FAILURE,
	payload: error,
});

export const createRoutePlanStart = (info) => ({
	type: RoutePlanActionTypes.CREATE_ROUTE_PLAN_START,
	payload : info
});

export const createRoutePlanSuccess = () => ({
	type: RoutePlanActionTypes.CREATE_ROUTE_PLAN_SUCCESS,
});

export const createRoutePlanFailure = (error) => ({
	type: RoutePlanActionTypes.CREATE_ROUTE_PLAN_FAILURE,
	payload: error,
});

export const clearRouteError = () => ({
	type: RoutePlanActionTypes.CLEAR_ERROR,
});
