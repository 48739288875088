import ActivitiesActionTypes from "./activities-type.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	singular: null,
	plural: [],
};

const activitiesTypeReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActivitiesActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case ActivitiesActionTypes.FETCH_ALL_ACTIVITIES_START:
		case ActivitiesActionTypes.CREATE_ACTIVITIES_START:
		case ActivitiesActionTypes.UPDATE_ACTIVITIES_START:
		case ActivitiesActionTypes.DELETE_ACTIVITIES_START:
			return {
				...state,
				loading: true,
			};
		case ActivitiesActionTypes.FETCH_ALL_ACTIVITIES_FAILURE:
		case ActivitiesActionTypes.CREATE_ACTIVITIES_FAILURE:
		case ActivitiesActionTypes.UPDATE_ACTIVITIES_FAILURE:
		case ActivitiesActionTypes.DELETE_ACTIVITIES_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case ActivitiesActionTypes.CREATE_ACTIVITIES_SUCCESS: 
		case ActivitiesActionTypes.UPDATE_ACTIVITIES_SUCCESS: 
		case ActivitiesActionTypes.DELETE_ACTIVITIES_SUCCESS:{
			return {
				...state,
				loading: false,
			};
		}
		case ActivitiesActionTypes.FETCH_ALL_ACTIVITIES_SUCCESS: {
			return {
				...state,
				plural: action.payload,
				loading: false,
			};
		}
		default:
			return state;
	}
};

export default activitiesTypeReducer;
